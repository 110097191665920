import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import duration from 'dayjs/plugin/duration'
import { TLocale } from './types'

import 'dayjs/locale/de'
import 'dayjs/locale/en'
import 'dayjs/locale/ro'

// dayjs init
dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.extend(timezone)
dayjs.extend(duration)
dayjs.tz.setDefault('Europe/Zurich')
dayjs.locale(TLocale.DE)

// Debug log the client's timezone
// console.log('> timezone', dayjs.tz.guess())
