import styled, { css } from 'styled-components/macro'

import { WarningIcon } from 'src/components/toast/toastIcons'
import { Section } from 'src/components/section/Section'

type TErrorType = 'warning' | 'error'

type Props = {
  errorText: string
  type?: TErrorType
  traceId?: string
}

export const Error = ({ errorText, type = 'warning', traceId }: Props) => {
  return (
    <>
      <Section padding="all">
        <ErrorWrap $type={type}>
          <WarningIcon />
          <ErrorText>
            <div>{errorText}</div>
            {traceId && <ErrorTextSub>X-Trace-ID: {traceId}</ErrorTextSub>}
          </ErrorText>
        </ErrorWrap>
      </Section>
    </>
  )
}

const ErrorWrap = styled.div<{ $type?: TErrorType }>`
  display: flex;
  align-items: flex-start;
  padding: 16px;
  color: ${({ theme }) => theme.components.Toast.defaultColor};
  border-radius: ${({ theme }) => theme.components.Toast.defaultBorderRadius};
  ${({ $type }) => {
    if ($type === 'error') {
      return css`
        background-color: ${({ theme }) => theme.components.Toast.errorBackgroundColor};
        border: 1px solid ${({ theme }) => theme.components.Toast.errorBorderColor};
      `
    }
    return css`
      background-color: ${({ theme }) => theme.components.Toast.warningBackgroundColor};
      border: 1px solid ${({ theme }) => theme.components.Toast.warningBorderColor};
    `
  }}

  * + * {
    margin-left: 8px;
  }

  > svg {
    width: 18px;
    min-width: 18px;
    height: 18px;
  }

  > svg path {
    ${({ $type }) => {
      if ($type === 'error') {
        return css`
          fill: ${({ theme }) => theme.components.Toast.errorBorderColor};
        `
      }
      return css`
        fill: ${({ theme }) => theme.components.Toast.warningBorderColor};
      `
    }}
  }
`

const ErrorText = styled.span`
  font-size: 18px;
  line-height: 23px;
  display: inline-block;
`

const ErrorTextSub = styled.div`
  margin-left: 0;
  font-size: 16px;
`
