import styled from 'styled-components/macro'

import { ReactComponent as CopyToClipboardIcon } from 'src/assets/images/share/LinkCopy.svg'
import { ReactComponent as CopyToClipboardIconMobile } from 'src/assets/images/share/LinkCopy_mobile.svg'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { shareButtonStyles } from './styles'
import { useMedia } from 'src/utils/media/useMedia'

type Props = {
  urlToShare?: string
  onCopy?: (text: string, result: boolean) => void
  disabled?: boolean
  isMobile: boolean
}

export const CopyToClipboardShareButton = ({ urlToShare, onCopy, disabled }: Props) => {
  const url = urlToShare ? urlToShare : window.location.href
  const { isMobile } = useMedia()

  return (
    <CopyToClipboard text={url} onCopy={onCopy}>
      <StyledShareButton $disabled={disabled}>
        {isMobile ? <CopyToClipboardIconMobile /> : <CopyToClipboardIcon />}
      </StyledShareButton>
    </CopyToClipboard>
  )
}

const StyledShareButton = styled.a<{ $disabled?: boolean }>`
  ${shareButtonStyles}
  ${({ $disabled }) => {
    if ($disabled) {
      return `
        opacity:.5;
        pointer-events:none;
      `
    }
  }}
`
