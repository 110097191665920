import { userDashBoardTranslations } from './userDashBoard'
import { navigationLinksTranslations } from './navigationLinks'
import { rulesTranslations } from './rules'
import { crosswordOfTheDayTranslations } from './crosswordOfTheDay'
import { archiveTranslations } from './archive'
import { modalsTranslations } from './modals'
import { TLocale } from 'src/utils/date/types'

export const translations = {
  userDashBoard: userDashBoardTranslations,
  navigationLinks: navigationLinksTranslations,
  rules: rulesTranslations,
  crosswordOfTheDay: crosswordOfTheDayTranslations,
  archive: archiveTranslations,
  modals: modalsTranslations,
  dateLocale: TLocale.DE,
}
