import { ComponentProps, useContext } from 'react'
import { Button } from 'src/components/buttons/Button'
import { Modal, ModalButtonsWrapper, ModalTitle } from 'src/components/modal'
import { TranslationsContext } from 'src/translations/TranslationsContext'

type Props = ComponentProps<typeof Modal>

export const StillWrongModal = ({ isOpen, onClose }: Props) => {
  const translations = useContext(TranslationsContext)
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalTitle>{translations.modals.stillWrong.title}</ModalTitle>
      <ModalButtonsWrapper>
        <Button fullWidth={true} componentTheme="primary" onClick={onClose}>
          {translations.modals.stillWrong.keepTrying}
        </Button>
      </ModalButtonsWrapper>
    </Modal>
  )
}
