import { useMediaQuery } from 'react-responsive'
import { deviceWidths } from 'src/theme/media'

export const useMedia = () => {
  const normalizedUserAgent = navigator.userAgent.toLowerCase()
  const isDesktop = useMediaQuery({ query: `(min-width: ${deviceWidths.mobile}px)` })
  const isMobile = !isDesktop // This changes as we introduce tablet
  const isMobileDevice = /(android|iphone|ipad|mobile)/i.test(normalizedUserAgent)
  const isAndroid = /android/.test(normalizedUserAgent)
  const isAndroidWebView = isAndroid && /; wv\)/.test(normalizedUserAgent)

  return { isDesktop, isMobile, isMobileDevice, isAndroid, isAndroidWebView }
}
