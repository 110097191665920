import qs from 'query-string'

// Shorthand to combine urls with api params
export const buildUrl = (url: string, params?: Record<string, unknown>) =>
  !params ? url : url + '?' + qs.stringify(params)

export const getOptionalParameter = (parameter: AnyObject) => {
  const value = Object.values(parameter)[0]
  const key = Object.keys(parameter)[0]
  return value ? `&${key}=${value}` : ''
}
