import { useEffect, useMemo, useState, useLayoutEffect } from 'react'

import { throttle } from 'lodash-es'
import { useMedia } from 'src/utils/media/useMedia'
import { useNavigate, useLocation, matchPath, useSearchParams, generatePath } from 'react-router-dom'
import { stripUnit } from 'src/utils/style/stripUnit'
import { Path } from 'src/router/enums'
import { useTheme } from 'src/theme/useTheme'

export type TNavLink = {
  hash: string
  title: string
  to: string
}

export const navHashes = {
  start: 'start',
  crosswords: 'crosswords',
  faq: 'faq',
}

const navLinks = [
  {
    title: 'Start',
    hash: '#start',
    to: 'libertatea', // productId
  },
  {
    title: 'Rebusul zilei',
    hash: '#crosswords',
    to: 'libertatea', // productId
  },
  {
    title: 'Faq',
    hash: '#faq',
    to: 'libertatea', // productId
  },
  {
    title: 'Arhivă',
    hash: '',
    to: generatePath(Path.Archive, { productName: 'libertatea' }),
    isSeparatePage: true,
  },
]

export const useNavigation = () => {
  const navigate = useNavigate()
  const { isDesktop } = useMedia()
  const [initialNavigation, setInitialNavigation] = useState(true)
  // const userSub = useSelector(getUserSub)
  const { theme } = useTheme()
  const [searchParams] = useSearchParams()
  const scrollToIsActive = searchParams.get('scrollTo') // use to detirmine if there is scrolling effect from redirect/navigation

  const headerHeight = isDesktop
    ? stripUnit(theme.measures.navigation.headerHeightDesktop)
    : stripUnit(theme.measures.navigation.headerHeightMobile)
  const threshold = stripUnit(theme.measures.navigation.navigationScrollThreshold) //px

  const location = useLocation()
  const currentHash = location.hash

  const onNavigationClick = (title: string, hash: string) => {
    console.log('onNavigationClick')
    // trackNavigationClick(title, hash, userSub) // TODO:GA
  }

  const handleScroll = useMemo(
    () =>
      throttle((e) => {
        const activeHash = navLinks.reduce(
          (acc, { hash }) => {
            if (!hash) {
              return acc
            }

            const element = document.querySelector(hash)
            const rect = element?.getBoundingClientRect()
            const top = rect?.top || -1
            if (top < headerHeight + threshold) {
              acc = hash
            }
            return acc
          },
          navLinks[0]?.hash || '',
        )

        if (activeHash && currentHash !== activeHash) {
          navigate(activeHash, { replace: true })
        }
      }, 50),
    [navigate, headerHeight, threshold, currentHash],
  )

  useLayoutEffect(() => {
    if (!scrollToIsActive) {
      const isProductPath = matchPath(Path.Product, location.pathname)

      //To avoid routing conflicts on other routes
      if (isProductPath) {
        window.addEventListener('scroll', handleScroll)
      } else {
        window.removeEventListener('scroll', handleScroll)
      }

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [handleScroll, location.pathname, scrollToIsActive])

  useEffect(() => {
    if (!location.hash) {
      setInitialNavigation(false)
      return
    }
    if (initialNavigation && location.hash) {
      const maxChecks = 15
      let countner = 0
      const ifElementExistsInterval = setInterval(() => {
        const elementId = location.hash.replace('#', '')
        const element = document.getElementById(elementId)
        if (element) {
          // TO DO check how to avoid this hacky solution for navigation
          window.location.href = window.location.href // eslint-disable-line
          setInitialNavigation(false)
          clearInterval(ifElementExistsInterval)
        }

        if (countner === maxChecks) {
          setInitialNavigation(false)
          clearInterval(ifElementExistsInterval)
        }

        countner++
      }, 100)
    }
  }, [initialNavigation, location])

  return {
    navLinks,
    onNavigationClick,
    currentHash,
  }
}
