import styled from 'styled-components/macro'

import { ReactComponent as EmailIcon } from 'src/assets/images/share/Email.svg'
import { ReactComponent as EmailIconMobile } from 'src/assets/images/share/Email_mobile.svg'
import { objectToGetParams } from './utils'
import { shareButtonStyles } from './styles'
import { useMedia } from 'src/utils/media/useMedia'

type Props = {
  urlToShare?: string
  body?: string
  subject?: string
  disabled?: boolean
  isMobile: boolean
  onClick?: () => void
}

function emailLink(url: string, body?: string, subject?: string) {
  return (
    'mailto:' +
    objectToGetParams({
      subject,
      body: body ? `${body} \n ${url}` : url,
    })
  )
}

export const EmailShareButton = ({ urlToShare, body, subject, disabled, ...restProps }: Props) => {
  const url = urlToShare ? urlToShare : window.location.href
  const link = emailLink(url, body, subject)
  const { isMobile } = useMedia()

  return (
    <StyledShareButton href={link} $disabled={disabled} {...restProps}>
      {isMobile ? <EmailIconMobile /> : <EmailIcon />}
    </StyledShareButton>
  )
}

const StyledShareButton = styled.a<{ $disabled?: boolean }>`
  ${shareButtonStyles}
  ${({ $disabled }) => {
    if ($disabled) {
      return `
        opacity:.5;
        pointer-events:none;
      `
    }
  }}
`
