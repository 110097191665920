import { useContext } from 'react'
import styled from 'styled-components/macro'
import { TranslationsContext } from 'src/translations/TranslationsContext'

import { Section, SectionKey, SectionRow, SectionTitle, SectionValue } from 'src/containers/UserDashBoard/components'
import { useDashBoardFooterActions } from 'src/containers/UserDashBoard/useDashBoardFooterActions'

import { ReactComponent as LibertateaSettingsIcon } from 'src/assets/images/libertatea/settings_icon.svg'
import { ReactComponent as LibertateaLogoutIcon } from 'src/assets/images/libertatea/logout_icon.svg'
import { ReactComponent as LibertateaGameCenterIcon } from 'src/assets/images/libertatea/gamecenter_icon.svg'
import { Spacer } from 'src/components/Spacer'
import { useSelector } from 'react-redux'
import { getUserStats } from 'src/api/user/selectors'

export const UserDashBoardContentAlt = () => {
  const translations = useContext(TranslationsContext)
  const userStats = useSelector(getUserStats)
  const { onClickLogout, onClickProfile, onClicкGameCenter } = useDashBoardFooterActions()

  return (
    <>
      <Section>
        <SectionTitle>{translations.userDashBoard.currentMonth}</SectionTitle>
        {/* {userSummary &&
          getUserStatsContent(userSummary, productId).currentRound.map((item) => ( */}
        <SectionRow key={'Crosswords completed'}>
          <SectionKey>{userStats?.numCrosswordsCompletedThisMonth}</SectionKey>

          <SectionValue>{translations.userDashBoard.completedCorrectly}</SectionValue>
        </SectionRow>
        <SectionRow key={'Crosswords completed'}>
          <SectionKey>{userStats?.pointsEarnedThisMonth}</SectionKey>
          <SectionValue>{translations.userDashBoard.pointsEarned}</SectionValue>
        </SectionRow>
        {/* ))} */}
      </Section>
      <Section>
        <ActionButton onClick={onClicкGameCenter}>
          <StyledLibertateaGameCenterIcon />
          <span>{translations.userDashBoard.moreGames}</span>
        </ActionButton>
        <Spacer size={18} $v />
        <ActionButton onClick={onClickProfile}>
          <StyledLibertateaSettingsIcon />
          <span>{translations.userDashBoard.myAccount}</span>
        </ActionButton>
        <Spacer size={18} $v />
        <ActionButton onClick={onClickLogout}>
          <StyledLibertateaLogoutIcon />
          <span>{translations.userDashBoard.logout}</span>
        </ActionButton>
      </Section>
    </>
  )
}

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  ${({ theme }) => theme.components.UserDashBoard.actionButtonTextStyle}
`

const StyledLibertateaGameCenterIcon = styled(LibertateaGameCenterIcon)`
  margin-right: 8px;
  margin-left: -3px;
`

const StyledLibertateaSettingsIcon = styled(LibertateaSettingsIcon)`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`

const StyledLibertateaLogoutIcon = styled(LibertateaLogoutIcon)`
  height: 18px;
  width: 22px;
  margin-right: 8px;
`
