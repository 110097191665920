import styled from 'styled-components/macro'

import { ReactComponent as TwitterIcon } from 'src/assets/images/share/Twitter.svg'
import { ReactComponent as TwitterIconMobile } from 'src/assets/images/share/Twitter_mobile.svg'
import { TCommonSocialMediaButtonProps } from './types'
import { objectToGetParams } from './utils'
import { shareButtonStyles } from './styles'

function twitterLink(url: string, title?: string) {
  return (
    'https://twitter.com/share' +
    objectToGetParams({
      url,
      text: title,
    })
  )
}

export const TwitterShareButton = ({
  urlToShare,
  title,
  target = '_self',
  disabled,
  isMobile,
  ...restProps
}: TCommonSocialMediaButtonProps) => {
  const url = urlToShare ? urlToShare : window.location.href
  const link = twitterLink(url, title)

  return (
    <StyledShareButton href={link} target={target} $disabled={disabled} {...restProps}>
      {isMobile ? <TwitterIconMobile /> : <TwitterIcon />}
    </StyledShareButton>
  )
}

const StyledShareButton = styled.a<{ $disabled?: boolean }>`
  ${shareButtonStyles}
  ${({ $disabled }) => {
    if ($disabled) {
      return `
        opacity:.5;
        pointer-events:none;
      `
    }
  }}
`
