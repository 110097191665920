// import ipuzData from 'src/assets/crosswords/northwesteasy.json'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { CrosswordForDayContainer } from 'src/containers/CrosswordForDay/CrosswordForDayContainer'
import { fetchCrosswordState, fetchCrosswords } from 'src/api/crosswords/actions'
import { getCrosswordStateById, getCrosswordsForDate } from 'src/api/crosswords/selectors'
import { TDateISODate } from 'src/utils/date/formatDate'
import { setHeaderActive } from 'src/state/app/actions'
import { useMedia } from 'src/utils/media/useMedia'
import { getIsAuthResolved, getIsLoggedIn } from 'src/api/auth/selectors'
import { login } from 'src/api/auth/actions'

export const CrosswordForDay = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { productName, date } = useParams()
  const { isMobile } = useMedia()
  const isLoggedIn = useSelector(getIsLoggedIn)
  const isAuthResolved = useSelector(getIsAuthResolved)
  const crossword = useSelector(getCrosswordsForDate(date as TDateISODate))
  const crosswordState = useSelector(getCrosswordStateById(crossword?.id))

  const ipuzData = useMemo(() => {
    const data = crossword ? Object.assign({}, crossword.ipuz) : null
    if (!data) {
      return null
    }
    const puzzle = (data.puzzle as Array<unknown[]>) || []
    data.version = 'http://ipuz.org/v2'
    // data.kind = ['http://ipuz.org/crossword#1']
    data.solution = puzzle.map((row) => row.map((col) => (col === '#' ? '#' : '_')))
    return data
  }, [crossword])

  useEffect(() => {
    if (isAuthResolved && !isLoggedIn) {
      dispatch(login('method', { returnUrl: window.location.href }))
    }
  }, [isLoggedIn, isAuthResolved, navigate, productName, dispatch])

  useEffect(() => {
    if (!crossword && productName) {
      dispatch(fetchCrosswords({ productId: productName, filter: date as TDateISODate }))
    }
  }, [dispatch, date, productName, crossword])

  useEffect(() => {
    if (!crosswordState && crossword) {
      dispatch(fetchCrosswordState(crossword.id))
    }
  }, [crosswordState, crossword, dispatch])

  useEffect(() => {
    isMobile && dispatch(setHeaderActive(false))

    return () => {
      dispatch(setHeaderActive(true))
    }
  }, [dispatch, isMobile])

  if (!ipuzData || !crosswordState || !productName) {
    return <></>
  }

  return (
    <>
      <CrosswordForDayContainer data={ipuzData} crosswordState={crosswordState} productName={productName} />
    </>
  )
}
