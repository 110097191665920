import styled, { css } from 'styled-components/macro'

import { pixelify } from 'src/utils/style/pixelify'
import { stripUnit } from 'src/utils/style/stripUnit'
import { theme } from 'src/components/skeleton/theme'

type Props = {
  width: string | number
  height?: string | number
  center?: boolean
}

export const LineElement = styled.div<Props>`
  position: relative;
  align-self: flex-end;
  width: calc(100% - ${({ width }) => pixelify(width)});
  min-height: ${({ height }) => (height ? pixelify(height) : theme.DEFAULT_LINE_HEIGHT)};
  background-color: ${theme.BACKGROUND_COLOR};

  ${({ center, width }) => center && getCenterStyle(width)}
`

const getCenterStyle = (width: string | number) => {
  let sideWidth
  const isPercentage = typeof width === 'string' && width.indexOf('%') >= 0

  if (isPercentage) {
    sideWidth = (100 - stripUnit(width)) / 2 + '%'
  } else {
    sideWidth = pixelify(width)
  }

  return css<Props>`
    width: 100%;
    background-color: transparent;

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      content: ' ';
      background-color: ${theme.BACKGROUND_COLOR};
      width: ${sideWidth};
      min-height: ${({ height }) => (height ? pixelify(height) : theme.DEFAULT_LINE_HEIGHT)};
    }

    &:after {
      position: absolute;
      right: 0;
      top: 0;
      display: block;
      content: ' ';
      background-color: ${theme.BACKGROUND_COLOR};
      width: ${sideWidth};
      min-height: ${({ height }) => (height ? pixelify(height) : theme.DEFAULT_LINE_HEIGHT)};
    }
  `
}
