import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { THeroStyle } from 'src/theme/themes/default/components/hero'

const hero: THeroStyle = defaultsDeep({}, defaultTheme.components.Hero)

hero.width = '100vw'
hero.margin = `0 0 0 calc((${defaultTheme.measures.contentWidth} - 100vw) / 2)`
hero.padding = '0'
hero.slideDesktopHeight = '360px'
hero.slideMobileHeight = 'auto'
hero.slideText.textAlign = 'center'
hero.slideText.desktop.width = '231px'
hero.slideText.desktop.left = '202px'
export const libertateaHero = hero
