import React from 'react'
import styled from 'styled-components/macro'

import { Swiper, SwiperSlide, SwiperProps } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/effect-fade'
import { SwiperEvents } from 'swiper/types/swiper-events'
import { SwiperOptions } from 'swiper/types/swiper-options'
import { PaginationOptions } from 'swiper/types/modules/pagination'

type TSliderEvents = {
  onInit?: SwiperEvents['init']
  onSlideChangeTransitionStart?: SwiperEvents['slideChangeTransitionStart']
  onSlideChange?: SwiperEvents['slideChange']
}

export type TSliderSettings = SwiperOptions & TSliderEvents

type Props = {
  className?: string
  settings?: TSliderSettings
  children?: React.ReactNode
} & SwiperProps

// swiper
// https://swiperjs.com/swiper-api
// types
// https://swiperjs.com/types/modules
export const Slider = ({ className, settings, children, ...restProps }: Props) => {
  if (settings?.pagination) {
    ;(settings?.pagination as PaginationOptions).el = cssSelectors.Pagination
  }

  return (
    <Swiper className={className} {...settings} {...restProps}>
      {React.Children.map(children, (child) => (
        <SwiperSlide>{child}</SwiperSlide>
      ))}
      {settings?.pagination && <Pagination className={cssSelectors.Pagination.replace('.', '')} />}
    </Swiper>
  )
}

export const cssSelectors = {
  Swiper: '.swiper',
  Pagination: '.swiper-pagination',
  PaginationBullet: '.swiper-pagination-bullet',
  PaginationBulletActive: '.swiper-pagination-bullet-active',
  NavigationPrev: '.swiper-button-prev',
  NavigationNext: '.swiper-button-next',
  NavigationDisabled: '.swiper-button-disabled',
}

const Pagination = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 10px;
  width: 100%;
  justify-content: center;
  display: flex;

  ${cssSelectors.PaginationBullet} {
    width: 12px;
    height: 12px;
    background: ${({ theme }) => theme.colors.shades.grey};
    border-radius: 14px;
    margin: 0 4px;
    cursor: pointer;
  }

  ${cssSelectors.PaginationBulletActive} {
    background: ${({ theme }) => theme.colors.shades.white};
  }
`
