import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Tenants } from 'src/enums/tenants'
import { getTenant } from 'src/utils/tenant'

export const Home = () => {
  const navigate = useNavigate()
  const tenant = getTenant()

  //Default redirect
  useEffect(() => {
    switch (tenant) {
      //   case Tenants.ONET:
      //     return navigate('/onet')

      case Tenants.LIBERTATEA:
        return navigate('/libertatea')

      default:
        return navigate('/libertatea')
    }
  }, [navigate, tenant])

  return <>Home/Landing page</>
}
