import { colors } from 'src/theme/colors'

export type TRulesStyle = typeof defaultRules

export const defaultRules = {
  textColor: colors.shades.black,
  itemsIndent: 16,
  marginBottomDesktop: '130px',
  marginBottomMobile: '40px',
  textStyle: `
    font-size: 16px;
    line-height: 20px;
  `,
  padding: '0 16px 16px 50px',
  paddingMobile: '0 16px 16px 41px',
}
