import { SectionTitle } from 'src/components/section/SectionTitle'
import { Social } from './Social'
import { Spacer } from 'src/components/Spacer'
import styled from 'styled-components/macro'
import { useContext } from 'react'
import { TranslationsContext } from 'src/translations/TranslationsContext'
import { libertateaColors } from 'src/theme/themes/libertatea/colors'

export const SocialLayoutVersion = () => {
  const translations = useContext(TranslationsContext)
  return (
    <Wrapper>
      <SectionTitle>{translations.crosswordOfTheDay.shareSectionTitle}</SectionTitle>
      <Spacer size={16} $v />
      <Social
        color={libertateaColors.greyDark}
        shareSubject={translations.crosswordOfTheDay.shareSubject}
        shareTitle={translations.crosswordOfTheDay.shareTitle}
        urlToShare={window.location.href}
        isLayoutVersion
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 0 16px;
`
