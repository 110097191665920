import { Route, Routes } from 'react-router-dom'
import { Footer } from 'src/components/libertatea/Footer/Footer'
import { Header } from 'src/containers/libertatea/Header'
import { AppEntryPoint } from './AppEntryPoint'
import { Path } from 'src/router/enums'
import { Product } from 'src/routes/libertatea/Product'
import 'src/assets/fonts/libertatea/fonts.css'
import { Archive } from 'src/routes/Archive'
import { CrosswordForDay } from 'src/routes/CrosswordForDay'
import { Home } from 'src/routes/Home'

export const LibertateaLayout = () => {
  return (
    <>
      <AppEntryPoint>
        <Header />
        <Routes>
          <Route path={Path.Home} element={<Home />} />
          <Route path={Path.Product} element={<Product />} />
          <Route path={Path.Archive} element={<Archive />} />
          <Route path={Path.CrossowordForDay} element={<CrosswordForDay />} />
        </Routes>
      </AppEntryPoint>
      <Footer />
    </>
  )
}
