import styled from 'styled-components/macro'
import { Autoplay, Pagination } from 'swiper/modules'
import { Slider, TSliderSettings } from 'src/components/Slider'
import { useMedia } from 'src/utils/media/useMedia'
import { Image } from 'src/components/Image'
import { Markdown } from 'src/components/Markdown'
import { useSelector } from 'react-redux'
import { getLatestCrossword } from 'src/api/crosswords/selectors'
import { getProductFromPath } from 'src/utils/auth'
import { Path } from 'src/router/enums'
import { generatePath } from 'react-router-dom'

type Props = {
  id?: string
}

type THeroSettings = {
  images: {
    url: string
    text: string
    mobileUrl: string
  }[]
  logo: string
}

export const Hero = ({ id }: Props) => {
  const crossword = useSelector(getLatestCrossword)
  const productId = getProductFromPath()

  const heroSettings: THeroSettings = {
    images: [
      {
        url: '/assets/hero/libertatea/desktop.svg',
        text: `[Joacă acum!](${
          crossword
            ? generatePath(Path.CrossowordForDay, {
                productName: productId || '',
                date: crossword.day,
              })
            : '/libertatea/archive'
        })`,
        mobileUrl: '/assets/hero/libertatea/mobile.svg',
      },
    ],
    logo: '',
  }

  const { isDesktop } = useMedia()
  const multipleImages = Boolean(heroSettings && heroSettings.images && heroSettings.images.length > 1)
  const sliderSettings: TSliderSettings = {
    modules: [Autoplay, Pagination],
    slidesPerView: 1,
    autoHeight: true,
    loop: multipleImages,
    autoplay: multipleImages
      ? {
          delay: 4000,
        }
      : false,
    pagination: multipleImages
      ? {
          clickable: true,
        }
      : false,
  }

  return (
    <Wrapper id={id}>
      <InnerWrapper>
        {heroSettings?.logo && <Logo src={heroSettings.logo} />}
        <Slider settings={sliderSettings}>
          {heroSettings?.images?.map((image, key) => (
            <div key={key}>
              <>
                {isDesktop && <SlideImage src={image.url} />}
                {!isDesktop && <SlideImage src={image.mobileUrl} />}
              </>
              <SlideText>
                <Markdown linkAsButton>{image.text}</Markdown>
              </SlideText>
            </div>
          ))}
        </Slider>
      </InnerWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  scroll-margin-top: ${({ theme }) => theme.components.common.scrollMarginTop};
  position: relative;
  background: ${({ theme }) => theme.components.Hero.background};
  padding: ${({ theme }) => theme.components.Hero.paddingMobile};

  ${({ theme }) => theme.media.isDesktop} {
    width: ${({ theme }) => theme.components.Hero.width};
    margin: ${({ theme }) => theme.components.Hero.margin};
    padding: ${({ theme }) => theme.components.Hero.padding};
  }
`

const InnerWrapper = styled.div`
  width: 100%;
  margin: auto;
  max-width: ${({ theme }) => theme.measures.contentWidth};
  background: ${({ theme }) => theme.colors.shades.white};
  position: relative;
`

const SlideImage = styled.img`
  height: ${({ theme }) => theme.components.Hero.slideMobileHeight};
  width: 100%;
  object-fit: cover;
  object-position: ${({ theme }) => theme.components.Hero.imagePosition};
  ${({ theme }) => theme.media.isDesktop} {
    height: ${({ theme }) => theme.components.Hero.slideDesktopHeight};
    width: 100%;
  }
`

const SlideText = styled.div`
  text-align: ${({ theme }) => theme.components.Hero.slideText.textAlign};
  margin: 0;
  position: absolute;
  color: ${({ theme }) => theme.components.Hero.slideText.color};
  font-family: ${({ theme }) => theme.components.Hero.slideText.fontFamily};
  font-weight: ${({ theme }) => theme.components.Hero.slideText.fontWeight};
  width: ${({ theme }) => theme.components.Hero.slideText.width};
  min-width: 160px;
  font-size: ${({ theme }) => theme.components.Hero.slideText.fontSize};
  line-height: ${({ theme }) => theme.components.Hero.slideText.lineHeight};
  top: ${({ theme }) => theme.components.Hero.slideText.top};
  right: ${({ theme }) => theme.components.Hero.slideText.right};
  left: ${({ theme }) => theme.components.Hero.slideText.left};
  bottom: ${({ theme }) => theme.components.Hero.slideText.bottom};
  text-shadow: ${({ theme }) => theme.components.Hero.slideText.textShadow};
  margin: ${({ theme }) => theme.components.Hero.slideText.margin};

  li {
    letter-spacing: 0.01em;
    a {
      color: ${({ theme }) => theme.components.Hero.slideText.color};
    }
  }

  ${({ theme }) => theme.media.isDesktop} {
    width: ${({ theme }) => theme.components.Hero.slideText.desktop.width};
    font-size: ${({ theme }) => theme.components.Hero.slideText.desktop.fontSize};
    font-weight: ${({ theme }) => theme.components.Hero.slideText.desktop.fontWeight};
    line-height: ${({ theme }) => theme.components.Hero.slideText.desktop.lineHeight};
    right: ${({ theme }) => theme.components.Hero.slideText.desktop.right};
    top: ${({ theme }) => theme.components.Hero.slideText.desktop.top};
    bottom: ${({ theme }) => theme.components.Hero.slideText.desktop.bottom};
    left: ${({ theme }) => theme.components.Hero.slideText.desktop.left};
  }
`

export const Logo = styled(Image)`
  display: ${({ theme }) => (theme.components.Hero.showLogoMobile ? 'block' : 'none')};
  position: absolute;
  top: 0;
  left: ${({ theme }) => theme.components.Hero.logoPositionLeftMobile};
  width: 102px;
  z-index: 2;
  ${({ theme }) => theme.media.isDesktop} {
    display: ${({ theme }) => (theme.components.Hero.showLogo ? 'block' : 'none')};
    left: 0;
    width: 137px;
  }
`
