import { Tenants } from 'src/enums/tenants'
import { localStorageSetItem } from 'src/utils/localStorage'

let tenants: TenantsModel = {} // NOTE fallback for tests and development
if (process.env.REACT_APP_TENANTS) {
  tenants = JSON.parse(process.env.REACT_APP_TENANTS as string) as TenantsModel
}

// const hostname = window.location.hostname
// let currentTenant = Tenants.LIBERTATEA
// Object.keys(tenants).forEach((tenant) => {
// if (tenants[tenant].frontend_domain_name === hostname || tenants[tenant].frontend_url.indexOf(hostname) > -1) {
// currentTenant = tenant as Tenants
//   }
// })
// Temporary until liberatea migrate fully to new domain and we can rename the key in the dictionary
// if (hostname.includes('intrebarea-zilei.libertatea.ro')) {
//   currentTenant = Tenants.LIBERTATEA
// }

export interface TenantsModel {
  readonly [tenant: string]: {
    readonly backend_domain_name: string
    readonly frontend_domain_name: string
    readonly assets_domain_name: string
    readonly frontend_url: string
    readonly backend_url: string
    readonly profile_url: string
    readonly login_case: string
    readonly source: string
  }
}

export const setTenant = (tenant: Tenants) => {
  localStorageSetItem('tenant', tenant)
}

export const getTenant = () => {
  return Tenants.LIBERTATEA
}

export const getTenantConfig = (tenant: string) => {
  return tenants[tenant]
}
