import styled, { css } from 'styled-components/macro'

type Props = {
  $noBorder?: boolean
  $wide?: boolean
  $noHorizontalPadding?: boolean
  $noVerticalPadding?: boolean
  $noTopPadding?: boolean
  $noBottomPadding?: boolean
}

export const Section = styled.div<Props>`
  position: relative;
  padding: ${({ $wide, theme }) =>
    $wide ? theme.components.UserDashBoard.sectionWidePadding : theme.components.UserDashBoard.sectionPadding};
  ${({ $noHorizontalPadding }) =>
    $noHorizontalPadding &&
    css`
      padding-left: 0;
      padding-right: 0;
    `}
  ${({ $noVerticalPadding }) =>
    $noVerticalPadding &&
    css`
      padding-top: 0;
      padding-bottom: 0;
    `}
    ${({ $noTopPadding }) =>
    $noTopPadding &&
    css`
      padding-top: 0;
    `}
    ${({ $noBottomPadding }) =>
    $noBottomPadding &&
    css`
      padding-bottom: 0;
    `}

  &:empty {
    padding: 0 32px 24px;
  }
  &:not(:last-child) {
    &:after {
      display: ${({ $noBorder }) => ($noBorder ? 'none' : 'block')};
      content: '';
      position: absolute;
      width: calc(100% - 64px);
      height: 1px;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      background-color: ${({ theme }) => theme.components.UserDashBoard.sectionBorderColor};
    }
  }
  ${({ theme }) => theme.media.isMobile} {
    padding: ${({ theme }) => theme.components.UserDashBoard.sectionPaddingMobile};
    &:not(:last-child) {
      &:after {
        width: calc(100% - 32px);
      }
    }
  }
`
