import { TWordGuess } from 'src/components/Crossword/types'
import { TAnswerDirection, TWordPosition } from './types'

export const getWordPositionFromAnswer = (answers: TAnswerDirection[], guesses: TWordGuess[]): TWordPosition[] => {
  const positions: TWordPosition[] = []
  answers.forEach((word) => {
    const number = word.down ? word.down : word.across
    const direction = word.down ? 'down' : 'across'
    const guess = guesses.find((guess) => guess.number === number?.toString() && guess.direction === direction)
    if (guess) {
      const position: TWordPosition = {
        down: guess.down,
        across: guess.across,
        direction: guess.direction,
        number: guess.number,
      }
      positions.push(position)
    }
  })
  return positions
}
