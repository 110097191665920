import { translations as deDefaultTranslations } from './de/default'

import { enTranslations as enDefaultTranslations } from './en/default'

import { roTranslations as roDefaultTranslations } from './ro/default'

import { getTenant } from 'src/utils/tenant'
import { Tenants } from 'src/enums/tenants'
import { TTranslations } from './types'

export const getTranslations = (productId?: string): TTranslations => {
  const tenant = getTenant()
  switch (tenant) {
    case Tenants.LIBERTATEA:
      return getRoTranslations(productId)
    default:
      return getDeTranslations(productId)
  }
}

export const getDeTranslations = (productId?: string): TTranslations => {
  switch (productId) {
    default:
      return deDefaultTranslations
  }
}

export const getEnTranslations = (productId?: string): TTranslations => {
  switch (productId) {
    default:
      return enDefaultTranslations
  }
}

export const getRoTranslations = (productId?: string): TTranslations => {
  switch (productId) {
    default:
      return roDefaultTranslations
  }
}
