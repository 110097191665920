import styled from 'styled-components/macro'
import { useContext } from 'react'
import { DrawerContext } from 'src/drawer'
import { DRAWER_INDEX } from 'src/drawer/constants'

import { DrawerCloseButton, DrawerTitle } from 'src/drawer/components'
import { libertateaColors } from 'src/theme/themes/libertatea/colors'

import avatarPlaceholder from 'src/assets/images/user_icon.svg'
import { libertateaFonts } from 'src/theme/themes/libertatea/fonts'
import { getUserAvatarUrl, getUserNickName } from 'src/api/auth/selectors'
import { useSelector } from 'react-redux'
import { getUserStats } from 'src/api/user/selectors'
import { TranslationsContext } from 'src/translations/TranslationsContext'

const AVATAR_SIZE = 64

export const UserDashBoardHeader = () => {
  const translations = useContext(TranslationsContext)
  const { closeDrawer } = useContext(DrawerContext)

  const userNickName = useSelector(getUserNickName)
  const userAvatarUrl = useSelector(getUserAvatarUrl)
  const userStats = useSelector(getUserStats)

  const onCloseDashBoard = () => {
    closeDrawer(DRAWER_INDEX.USER_DASHBOARD)
  }

  return (
    <>
      <HeaderWrapper>
        <HeaderInfo>
          <UserAvatar src={userAvatarUrl || avatarPlaceholder} />
          <TotalPoints>
            {userStats?.pointsEarnedThisMonth} {translations.userDashBoard.totalPoints}
          </TotalPoints>
          <DrawerTitle>{userNickName}</DrawerTitle>
        </HeaderInfo>
        <StyledDrawerCloseButton onClick={onCloseDashBoard} />
      </HeaderWrapper>
    </>
  )
}

const HeaderWrapper = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.components.UserDashBoard.headerPadding};
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  position: relative;
  display: flex;
  justify-content: center;
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    background-color: ${libertateaColors.grey};
    width: calc(100% - 64px);
  }

  ${({ theme }) => theme.media.isMobile} {
    padding: ${({ theme }) => theme.components.UserDashBoard.headerPaddingMobile};
    &:after {
      width: calc(100% - 32px);
    }
  }
`

const HeaderInfo = styled.div``

const UserAvatar = styled.img`
  margin: 0 auto 8px;
  width: ${AVATAR_SIZE}px;
  height: ${AVATAR_SIZE}px;
  object-fit: cover;
  border-radius: 50%;
  vertical-align: bottom;
`

const TotalPoints = styled.div`
  background: ${libertateaColors.red};
  font-family: ${libertateaFonts.signika};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 0 4px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.shades.white};
  width: auto;
  margin-bottom: 8px;
`

const StyledDrawerCloseButton = styled(DrawerCloseButton)`
  position: absolute;
  right: 32px;
  top: 32px;
  ${({ theme }) => theme.media.isMobile} {
    right: 16px;
  }
`
