import { TModalsTranslations } from 'src/translations/de/default/modals'

export const modalsTranslations: TModalsTranslations = {
  confirmClear: {
    title: 'Ești sigur că vrei să resetezi rebusul și să începi un joc nou?',
    no: 'Nu, vreau să continui',
    yes: 'Da, resetează',
  },
  stillWrong: {
    title: 'Rebusul este completat, dar cel puțin un cuvânt este încă greșit...',
    keepTrying: 'Mai încearcă',
  },
  solved: {
    title: 'Felicitări!',
    subtitle: 'Ai câștigat 10 puncte!',
    share: 'Distribuie rebusul',
    close: 'Închide',
  },
}
