export type TModalStyle = typeof defaultModal

export const defaultModal = {
  borderRadius: '8px',
  baseFontWeight: 400,
  baseLineHeight: '23px',
  padding: '24px',
  modalTitlePadding: '0 24px 0 0',
  modalTitleStyle: `
    font-size: 22px;
    line-height: 25px;
  `,
}
