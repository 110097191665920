import styled, { css } from 'styled-components/macro'

export const SectionTitleWrapper = styled.div`
  position: relative;

  ${({ theme }) =>
    theme.components.InfoSection.titleAfterMobile &&
    css`
      ${({ theme }) => theme.media.isMobile} {
        &:after {
          ${({ theme }) => theme.components.InfoSection.titleAfterMobile}
        }
      }
    `}

  ${({ theme }) =>
    theme.components.InfoSection.titleAfter &&
    css`
      ${({ theme }) => theme.media.isDesktop} {
        &:after {
          ${({ theme }) => theme.components.InfoSection.titleAfter}
        }
      }
    `}
`

export const SectionTitle = styled.div`
  margin: 0 0 8px 0;
  position: relative;
  z-index: 1;
  padding: ${({ theme }) => theme.components.InfoSection.titlePadding};
  text-align: ${({ theme }) => theme.components.InfoSection.alignment};
  ${({ theme }) => theme.components.InfoSection.titleTextStyleMobile};

  ${({ theme }) => theme.media.isDesktop} {
    ${({ theme }) => theme.components.InfoSection.titleTextStyle};
  }
`
