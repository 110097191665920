import styled from 'styled-components/macro'

type Props = {
  padding: 'all' | 'horizontalOnly' | 'verticalOnly' | 'none'
}

export const Section = styled.section<Props>`
  margin: 0;
  padding: ${({
    padding,
    theme: {
      measures: { utils, section },
    },
  }) => {
    switch (padding) {
      case 'all':
        return section.padding
      case 'horizontalOnly':
        return utils.multiple(0, section.paddingHorizontal)
      case 'verticalOnly':
        return utils.multiple(section.paddingTop, 0, section.paddingBottom)
      case 'none':
        return 0
    }
  }};
`
