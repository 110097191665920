import styled, { css } from 'styled-components/macro'

import { FacebookShareButton } from 'src/components/buttons/share/FacebookShareButton'
import { TwitterShareButton } from 'src/components/buttons/share/TwitterShareButton'
import { WhatsappShareButton } from 'src/components/buttons/share/WhatsappShareButton'
import { EmailShareButton } from 'src/components/buttons/share/EmailShareButton'
import { CopyToClipboardShareButton } from 'src/components/buttons/share/CopyToClipboardShareButton'
import { ShareType } from 'src/components/buttons/share/enums'
import { useMedia } from 'src/utils/media/useMedia'
import { toasty } from 'src/components/toast/toasty'
import { libertateaColors } from 'src/theme/themes/libertatea/colors'
import { useContext } from 'react'
import { TranslationsContext } from 'src/translations/TranslationsContext'

type Props = {
  shareTitle: string
  shareSubject: string
  urlToShare: string
  onShareButtonClick?: (shareType: ShareType) => void
  color?: string
  isLayoutVersion?: boolean
}

export const Social = ({ urlToShare, onShareButtonClick, shareTitle, shareSubject, color, isLayoutVersion }: Props) => {
  const { isAndroidWebView, isMobile } = useMedia()
  const translations = useContext(TranslationsContext)

  const handleShareButttonClick = (shareType: ShareType) => {
    if (!onShareButtonClick) {
      return
    }
    onShareButtonClick(shareType)
  }

  const onCopy = () => {
    toasty(translations.crosswordOfTheDay.shareLinkCopied, { type: 'info' })
    onShareButtonClick && handleShareButttonClick(ShareType.Copy)
  }

  return (
    <>
      <SocialWrap color={color} isLayoutVersion={isLayoutVersion}>
        <FacebookShareButton
          urlToShare={urlToShare}
          title={shareTitle}
          target="_blank"
          onClick={() => handleShareButttonClick(ShareType.Facebook)}
          isMobile={isMobile}
        />
        <TwitterShareButton
          urlToShare={urlToShare}
          title={shareTitle}
          target="_blank"
          onClick={() => handleShareButttonClick(ShareType.Twitter)}
          isMobile={isMobile}
        />
        {!isAndroidWebView && (
          <WhatsappShareButton
            urlToShare={urlToShare}
            title={shareTitle}
            target="_blank"
            onClick={() => handleShareButttonClick(ShareType.Whatsapp)}
            isMobile={isMobile}
          />
        )}
        <EmailShareButton
          urlToShare={urlToShare}
          subject={shareSubject}
          body={shareTitle}
          onClick={() => handleShareButttonClick(ShareType.Email)}
          isMobile={isMobile}
        />
        <CopyToClipboardShareButton urlToShare={urlToShare} onCopy={onCopy} isMobile={isMobile} />
      </SocialWrap>
    </>
  )
}

const SocialWrap = styled.div<{ color?: string; isLayoutVersion?: boolean }>`
  display: flex;
  * + * {
    margin-left: 16px;
  }

  svg {
    height: 36px;
    width: 36px;
    path {
      fill: ${({ theme, color }) => color || theme.colors.shades.white};
    }
    circle {
      stroke: ${({ theme, color }) => color || theme.colors.shades.white};
    }
  }
  justify-content: center;

  ${({ theme, isLayoutVersion }) =>
    isLayoutVersion &&
    css`
      svg {
        height: 40px;
        width: 40px;
        &:hover {
          circle {
            stroke: ${libertateaColors.red};
          }
          path {
            fill: ${libertateaColors.red};
          }
        }
      }

      ${theme.media.isDesktop} {
        svg {
          height: 56px;
          width: 56px;
        }
      }
    `}
`
