import { TUserDashBoardTranslations } from 'src/translations/de/default/userDashBoard'

export const userDashBoardTranslations: TUserDashBoardTranslations = {
  currentMonth: 'Luna curentă',
  moreGames: 'Descoperă mai multe concursuri',
  myAccount: 'Setările contului',
  logout: 'Ieșire',
  completedCorrectly: 'rebusuri completate corect',
  pointsEarned: 'puncte câștigate',
  totalPoints: 'puncte în total',
}
