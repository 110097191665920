import { config } from 'src/config'
import { getTenant } from './tenant'
import { Tenants } from 'src/enums/tenants'

export const getAuthSource = (): string => {
  return ''
}

export const getAuthLoginCase = (): string => {
  const tenant = getTenant()
  if (tenant === Tenants.LIBERTATEA) {
    return 'game_dailyquiz_2023'
  }
  return ''
}

export const getSelfUrl = (): string => {
  const configSelfUrl = config.selfUrl || window.location.origin

  return configSelfUrl
}

export const getProductFromPath = () => {
  return window.location.pathname.split('/')[1]
}

export const saveUtmParams = (params: string) => {
  sessionStorage.setItem('utmParams', params)
}

export const getUtmParams = () => {
  const params = sessionStorage.getItem('utmParams')
  return params ? params : ''
}
