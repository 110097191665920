import { Reducer } from 'redux'

import { TActions, IAppState } from './types'

import { ActionTypes, DashboardTab } from './enums'

const initialState: IAppState = {
  isHeaderActive: true,
  isMiniHeaderActive: false,
  dashboardActiveTab: DashboardTab.MyPlayer,
  usedLoginComponent: undefined,
}

export const reducer: Reducer<IAppState, TActions> = (state = initialState, action): IAppState => {
  switch (action.type) {
    case ActionTypes.SET_HEADER_ACTIVE:
      return {
        ...state,
        isHeaderActive: action.payload.active,
      }
    case ActionTypes.SET_MINI_HEADER_ACTIVE:
      return {
        ...state,
        isMiniHeaderActive: action.payload.active,
      }
    case ActionTypes.SET_DASHBOARD_ACTIVE_TAB:
      return {
        ...state,
        dashboardActiveTab: action.payload,
      }
    case ActionTypes.SET_USED_LOGIN_COMPONENT:
      return {
        ...state,
        usedLoginComponent: action.payload,
      }
    default:
      return state
  }
}
