import { isNumber } from 'lodash-es'
import debounce from 'lodash-es/debounce'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  checkCrossword,
  checkWord,
  cleanUpdateCrosswordStateStates,
  updateCrosswordState,
  clearCorrectIncorrectWords,
} from 'src/api/crosswords/actions'
import {
  getCheckCrosswordLoading,
  getCheckCrosswordResolved,
  getCheckWordLoading,
  getCheckWordResolved,
  getCorrectWords,
  getIncorrectWords,
  getUpdateCrosswordStateLoading,
  getUpdateCrosswordStateResolved,
} from 'src/api/crosswords/selectors'
import { TCrosswordState } from 'src/api/crosswords/types'
import { Crossword, CrosswordProviderImperative, useIpuz } from 'src/components/Crossword'
import { Clue, Direction } from 'src/components/Crossword/types'
import { Button } from 'src/components/buttons/Button'
import styled, { css } from 'styled-components/macro'
import { ReactComponent as ArrowBackIcon } from 'src/assets/images/arrow_back_icon.svg'
import { ReactComponent as ArrowSmallDownIcon } from 'src/assets/images/arrow_down_small_icon.svg'
import { Spacer } from 'src/components/Spacer'
import { measures } from 'src/theme/measures'
import { useMedia } from 'src/utils/media/useMedia'
import { useOutsideClick } from 'src/utils/hooks/useOutsideClick'
import { useHeader } from 'src/containers/libertatea/Header/useHeader'
import { getUserAvatarUrl } from 'src/api/auth/selectors'
import { ReactComponent as AccountIcon } from 'src/assets/images/user_icon.svg'
import { MobileMenu } from 'src/containers/libertatea/Header/MobileNavigationMenu'
import { generatePath, useNavigate } from 'react-router-dom'
import { StillWrongModal } from './StillWrongModal'
import { ConfirmClearModal } from './ConfirmClearModal'
import { toasty } from 'src/components/toast/toasty'
import { SolvedModal } from './SolvedModal'
import { LottieAnimation } from 'src/components/lottie/LottieAnimation'
import confettiSoccer from 'src/components/lottie/json/confetti.json'
import { Path } from 'src/router/enums'
import { TranslationsContext } from 'src/translations/TranslationsContext'

type Props = {
  data: AnyObject
  crosswordState: TCrosswordState
  productName: string
}

enum CallTypes {
  WORD,
  CROSSWORD,
  UPDATE,
}

export const CrosswordForDayContainer = ({ data, crosswordState, productName }: Props) => {
  const translations = useContext(TranslationsContext)
  const ref = useRef<CrosswordProviderImperative>(null)
  const checkActionsWrapperRef = useRef<HTMLDivElement | null>(null)
  const clearActionsWrapperRef = useRef<HTMLDivElement | null>(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const ipuzData = useIpuz(data)
  const { isMobile, isDesktop } = useMedia()
  const { onClickUserInfo } = useHeader()

  const userAvatarUrl = useSelector(getUserAvatarUrl)
  const updateCrosswordStateLoading = useSelector(getUpdateCrosswordStateLoading)
  const updateCrosswordStateResolved = useSelector(getUpdateCrosswordStateResolved)
  const checkWordLoading = useSelector(getCheckWordLoading)
  const checkWordResolved = useSelector(getCheckWordResolved)
  const checkCrosswordLoading = useSelector(getCheckCrosswordLoading)
  const checkCrosswordResolved = useSelector(getCheckCrosswordResolved)
  const correctWords = useSelector(getCorrectWords)
  const incorrectWords = useSelector(getIncorrectWords)

  const [readyForSave, setReadyForSave] = useState(false)
  const [shouldCheckWord, setShouldCheckWord] = useState(false)
  const [shouldCheckCrossword, setShouldCheckCrossword] = useState(false)
  const [isCheckActionsOpen, setIsCheckActionsOpen] = useState(false)
  const [isClearActionsOpen, setIsClearActionsOpen] = useState(false)
  const [selectedClue, setSelectedClue] = useState<Clue>()
  const [isStillWrongModalOpen, setIsStillWrongModalOpen] = useState(false)
  const [isConfirmClearModalOpen, setIsConfirmClearModalOpen] = useState(false)
  const [isSolvedModalOpen, setIsSolvedModalOpen] = useState(false)
  const [isSolvedAnimationOpen, setIsSolvedAnimationOpen] = useState(false)
  const [isWordReadyToCheck, setIsWordReadyToCheck] = useState(false)
  const [callQueue, setCallQueue] = useState<CallTypes[]>([])
  const [shouldFinallyCheckCrossword, setShouldFinallyCheckCrossword] = useState(false)

  const isAllWordsFilled = ref.current?.getIpuzState()?.every((arr) => arr.every((item) => !!item))

  const checkSingleWord = useCallback(() => {
    const wordGuess = ref.current?.getGuess()
    if (wordGuess && isNumber(crosswordState.revision)) {
      dispatch(checkWord({ ...wordGuess, revision: crosswordState.revision, id: crosswordState.id }))
    }
    setShouldCheckWord(false)
  }, [crosswordState.revision, crosswordState.id, dispatch])

  const onCheckWord = useCallback(() => {
    setIsCheckActionsOpen(false)
    if (!updateCrosswordStateLoading) {
      setShouldCheckWord(true)
      !readyForSave && setReadyForSave(true)
    } else {
      setCallQueue((prev) => {
        prev.push(CallTypes.WORD)
        return [...new Set(prev)]
      })
    }
  }, [readyForSave, updateCrosswordStateLoading])

  const checkWholeCrossword = useCallback(() => {
    if (isNumber(crosswordState.revision)) {
      const wordGuesses = ref.current?.getGuesses()
      if (wordGuesses?.length) {
        dispatch(checkCrossword({ id: crosswordState.id, revision: crosswordState.revision, guesses: wordGuesses }))
      }
    }
    setShouldCheckCrossword(false)
  }, [dispatch, crosswordState])

  const onCheckCrossword = useCallback(() => {
    setIsCheckActionsOpen(false)
    if (!updateCrosswordStateLoading) {
      setShouldCheckCrossword(true)
      !readyForSave && setReadyForSave(true)
    } else {
      setCallQueue((prev) => {
        prev.push(CallTypes.CROSSWORD)
        return [...new Set(prev)]
      })
    }
  }, [readyForSave, updateCrosswordStateLoading])

  const onClearWord = () => {
    setIsClearActionsOpen(false)
    ref.current?.resetWord()
    !readyForSave && setReadyForSave(true)
  }

  const onClearCrossord = () => {
    setIsClearActionsOpen(false)
    setIsConfirmClearModalOpen(true)
  }

  const undoClearCrossword = (stateForUndo: (string | null)[][] | undefined) => {
    if (stateForUndo) {
      ref.current?.setIpuzState(stateForUndo)
      !readyForSave && setReadyForSave(true)
    }
  }

  const onClearConfirm = () => {
    const stateForUndo = ref.current?.getIpuzState()
    ref.current?.reset()
    !readyForSave && setReadyForSave(true)
    setIsConfirmClearModalOpen(false)
    dispatch(clearCorrectIncorrectWords())
    toasty(
      <>
        {translations.crosswordOfTheDay.clearCrosswordSuccess}
        <div>
          <UndoButton onClick={() => undoClearCrossword(stateForUndo)}>
            {translations.crosswordOfTheDay.undo}
          </UndoButton>
        </div>
      </>,
      { type: 'success' },
    )
  }

  const onCellChange = debounce(() => {
    setShouldFinallyCheckCrossword(true)
    if (!callQueue.includes(CallTypes.UPDATE)) {
      setCallQueue((prev) => {
        prev.push(CallTypes.UPDATE)
        return [...new Set(prev)]
      })
    }
  }, 1500)

  const onCellChangeClick = () => {
    setTimeout(() => {
      setIsWordReadyToCheck(!!(ref.current?.getGuess() && isNumber(crosswordState.revision)))
    }, 0)
    onCellChange()
  }

  const unfocusWord = () => {
    ref.current?.unfocus()
    setSelectedClue(undefined)
  }

  const onCellSelect = (row: number, col: number, direction: Direction, number: string) => {
    setSelectedClue(ref.current?.getClue())
    ref.current?.clearIncorrect(direction, number)
    setTimeout(() => {
      setIsWordReadyToCheck(!!(ref.current?.getGuess() && isNumber(crosswordState.revision)))
    }, 0)
  }

  const onClueSelect = (direction: Direction, number: string) => {
    ref.current?.clearIncorrect(direction, number)
  }

  const onBackClick = () => {
    navigate(-1)
  }

  const triggerSolvedModal = (value: boolean) => {
    setIsSolvedModalOpen(value)
    setIsSolvedAnimationOpen(value)
  }

  useOutsideClick(() => {
    setIsCheckActionsOpen(false)
  }, checkActionsWrapperRef)

  useOutsideClick(() => {
    setIsClearActionsOpen(false)
  }, clearActionsWrapperRef)

  const updateState = useCallback(() => {
    const state = ref.current?.getIpuzState()
    if (state) {
      const newState: TCrosswordState = {
        ...crosswordState,
        state,
      }
      dispatch(updateCrosswordState(newState))
      setReadyForSave(false)
    }
  }, [crosswordState, dispatch])

  useEffect(() => {
    if (crosswordState.state) {
      ref.current?.setIpuzState(crosswordState.state)
    }
  }, [crosswordState])

  useEffect(() => {
    if (callQueue.length) {
      switch (callQueue[0]) {
        case CallTypes.WORD:
          if (updateCrosswordStateResolved) {
            setCallQueue((prev) => {
              prev.shift()
              return prev
            })
            onCheckWord()
          }
          return
        case CallTypes.CROSSWORD:
          if (updateCrosswordStateResolved) {
            setCallQueue((prev) => {
              prev.shift()
              return prev
            })
            onCheckCrossword()
          }
          return
        case CallTypes.UPDATE:
          if (!updateCrosswordStateLoading) {
            setCallQueue((prev) => {
              prev.shift()
              return prev
            })
            updateState()
          }
          return
      }
    }
  }, [
    callQueue,
    checkSingleWord,
    onCheckCrossword,
    onCheckWord,
    updateCrosswordStateLoading,
    updateCrosswordStateResolved,
    updateState,
  ])

  useEffect(() => {
    if (readyForSave && !updateCrosswordStateLoading && !checkWordLoading && !checkCrosswordLoading) {
      if (!callQueue.includes(CallTypes.UPDATE)) {
        setCallQueue((prev) => {
          prev.push(CallTypes.UPDATE)
          return [...new Set(prev)]
        })
      }
    }
  }, [
    readyForSave,
    updateCrosswordStateLoading,
    crosswordState,
    dispatch,
    updateState,
    checkWordLoading,
    checkCrosswordLoading,
    callQueue,
  ])

  useEffect(() => {
    if (shouldCheckWord && updateCrosswordStateResolved) {
      checkSingleWord()
    }
  }, [shouldCheckWord, updateCrosswordStateResolved, checkSingleWord])

  useEffect(() => {
    if (shouldCheckCrossword && updateCrosswordStateResolved) {
      checkWholeCrossword()
    }
  }, [shouldCheckCrossword, updateCrosswordStateResolved, checkWholeCrossword])

  useEffect(() => {
    if (updateCrosswordStateResolved) {
      dispatch(cleanUpdateCrosswordStateStates())
    }
  }, [updateCrosswordStateResolved, dispatch])

  useEffect(() => {
    return () => {
      dispatch(clearCorrectIncorrectWords())
    }
  }, [dispatch])

  useEffect(() => {
    if (checkWordResolved) {
      unfocusWord()
    }
  }, [checkWordResolved])

  useEffect(() => {
    if (checkCrosswordResolved && crosswordState.isSolved) {
      triggerSolvedModal(true)
    }
  }, [checkCrosswordResolved, crosswordState.isSolved])

  useEffect(() => {
    if (!shouldFinallyCheckCrossword && checkCrosswordResolved && !crosswordState.isSolved && isAllWordsFilled) {
      setIsStillWrongModalOpen(true)
    }
    //should not be triggered with state change => shouldFinallyCheckCrossword
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkCrosswordResolved, crosswordState.isSolved, isAllWordsFilled])

  useEffect(() => {
    if (
      updateCrosswordStateResolved &&
      isAllWordsFilled &&
      !callQueue.includes(CallTypes.CROSSWORD) &&
      shouldFinallyCheckCrossword
    ) {
      onCheckCrossword()
      setShouldFinallyCheckCrossword(false)
    }
  }, [callQueue, isAllWordsFilled, onCheckCrossword, shouldFinallyCheckCrossword, updateCrosswordStateResolved])

  useEffect(() => {
    if (checkCrosswordResolved) {
      unfocusWord()
    }
  }, [checkCrosswordResolved])

  useEffect(() => {
    ref.current?.focus()
    setTimeout(() => {
      setSelectedClue(ref.current?.getClue())
    }, 100)
  }, [])

  if (!ipuzData) {
    return <></>
  }

  return (
    <Wrapper>
      {isSolvedAnimationOpen && (
        <AnimationWrapper>
          <LottieAnimation
            animationData={confettiSoccer}
            autoplay
            loop={0}
            onComplete={() => setIsSolvedAnimationOpen(false)}
          />
        </AnimationWrapper>
      )}
      {isDesktop && (
        <Title>
          <BackButton onClick={onBackClick}>
            <ArrowBackIcon />
            <Spacer $h size={8} />
            {translations.crosswordOfTheDay.back}
          </BackButton>
          {translations.crosswordOfTheDay.published}
        </Title>
      )}
      <ActionsWrapper>
        {isMobile && (
          <BackButton onClick={onBackClick}>
            <ArrowBackIcon />
          </BackButton>
        )}

        <Actions>
          <PlayArea $isSolved={crosswordState.isSolved}>
            <ActionsInnerWrapper>
              <CheckActionsWrapper ref={checkActionsWrapperRef} $isOpen={isCheckActionsOpen}>
                {isMobile && (
                  <Button onClick={() => setIsCheckActionsOpen(!isCheckActionsOpen)} componentTheme="ghost">
                    {translations.crosswordOfTheDay.check}
                    <ArrowSmallDownIcon />
                  </Button>
                )}
                {(isDesktop || isCheckActionsOpen) && (
                  <CheckActions>
                    <Button
                      onClick={onCheckWord}
                      componentTheme="ghost"
                      disabled={!selectedClue || !isWordReadyToCheck}
                    >
                      {translations.crosswordOfTheDay.checkWord}
                    </Button>
                    <Button
                      onClick={onCheckCrossword}
                      componentTheme="ghost"
                      disabled={!ref.current?.getGuesses()?.length}
                    >
                      {translations.crosswordOfTheDay.checkCrossword}
                    </Button>
                  </CheckActions>
                )}
              </CheckActionsWrapper>

              <ClearActionsWrapper ref={clearActionsWrapperRef} $isOpen={isClearActionsOpen}>
                {isMobile && (
                  <Button onClick={() => setIsClearActionsOpen(!isClearActionsOpen)} componentTheme="ghost">
                    {translations.crosswordOfTheDay.clear}
                    <ArrowSmallDownIcon />
                  </Button>
                )}
                {(isDesktop || isClearActionsOpen) && (
                  <ClearActions>
                    <Button onClick={onClearWord} componentTheme="ghost" disabled={!selectedClue}>
                      {translations.crosswordOfTheDay.clearWord}
                    </Button>
                    <Button onClick={onClearCrossord} componentTheme="ghost">
                      {translations.crosswordOfTheDay.clearCrossword}
                    </Button>
                  </ClearActions>
                )}
              </ClearActionsWrapper>
            </ActionsInnerWrapper>
          </PlayArea>
          {isMobile && (
            <UserActions>
              {userAvatarUrl ? <UserAvatar src={userAvatarUrl} onClick={onClickUserInfo} /> : <StyledUserIcon />}
              <MobileMenu hideText={true} />
            </UserActions>
          )}
        </Actions>
      </ActionsWrapper>
      <PlayArea $isSolved={crosswordState.isSolved}>
        <Crossword
          onCellChange={onCellChangeClick}
          data={ipuzData}
          ref={ref}
          correctWords={correctWords}
          incorrectWords={incorrectWords}
          isSolved={crosswordState.isSolved}
          onCellSelected={onCellSelect}
          onClueSelected={onClueSelect}
        />
      </PlayArea>

      {crosswordState.isSolved && (
        <SolvedButtons>
          <Button componentTheme="primary" onClick={() => navigate(generatePath(Path.Product, { productName }))}>
            {translations.crosswordOfTheDay.backToStart}
          </Button>
          <Spacer $h={!isMobile} $v={isMobile} size={16} />
          <Button componentTheme="secondary" onClick={() => navigate(generatePath(Path.Archive, { productName }))}>
            {translations.crosswordOfTheDay.showMoreCrosswords}
          </Button>
        </SolvedButtons>
      )}

      <StillWrongModal isOpen={isStillWrongModalOpen} onClose={() => setIsStillWrongModalOpen(false)} />

      <ConfirmClearModal
        isOpen={isConfirmClearModalOpen}
        onClose={() => setIsConfirmClearModalOpen(false)}
        onConfirm={onClearConfirm}
      />

      <SolvedModal
        isOpen={isSolvedModalOpen}
        onClose={() => triggerSolvedModal(false)}
        shareTitle={translations.crosswordOfTheDay.shareTitle}
        shareSubject={translations.crosswordOfTheDay.shareSubject}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-bottom: 60px;
`

const AnimationWrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 1001;
  margin: auto;
  width: 100%;
  max-width: ${({ theme }) => theme.measures.contentWidth};
`

const Title = styled.div`
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
  position: relative;
  margin-top: 40px;
  margin-bottom: 24px;
`

const BackButton = styled.div`
  display: flex;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  position: absolute;
  top: 8px;
  cursor: pointer;
  z-index: 1;
  padding-right: 15px;

  ${({ theme }) => theme.media.isMobile} {
    left: 8px;
  }
`

const PlayArea = styled.div<{ $isSolved: boolean }>`
  pointer-events: ${({ $isSolved }) => ($isSolved ? 'none' : 'auto')};
`

const ActionsWrapper = styled.div`
  position: relative;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.07);
  margin-bottom: 8px;
  padding-left: 32px;
  padding-right: 8px;

  ${({ theme }) => theme.media.isDesktop} {
    width: 100vw;
    margin-left: calc((${measures.contentWidth} - 100vw) / 2);
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.components.Crossword.actionsBorderColor};
    margin-bottom: 40px;
    box-shadow: none;
    padding: 0;
  }
`

const Actions = styled.div`
  width: 100%;
  max-width: ${measures.contentWidth};
  margin: auto;
  display: flex;
  height: 40px;
  justify-content: space-between;

  ${({ theme }) => theme.media.isDesktop} {
    justify-content: center;
  }

  ${Button} {
    height: 40px;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    text-transform: none;
    color: ${({ theme }) => theme.components.common.color};

    ${({ theme }) => theme.media.isMobile} {
      padding: 0 16px;
    }
  }
`

const ActionsInnerWrapper = styled.div`
  display: flex;
`

const CheckActionsWrapper = styled.div<{ $isOpen?: boolean }>`
  position: relative;
  ${({ $isOpen, theme }) =>
    $isOpen &&
    css`
      ${Button} {
        color: ${theme.components.common.hightlightColor};

        svg {
          transform: rotate(-180deg);

          path {
            fill: ${theme.components.common.hightlightColor};
          }
        }
      }
    `}
`

const ClearActionsWrapper = styled.div<{ $isOpen?: boolean }>`
  position: relative;
  ${({ $isOpen, theme }) =>
    $isOpen &&
    css`
    ${Button} {
      color: ${theme.components.common.hightlightColor};

      svg {
          transform: rotate(-180deg);
    
          path {
            fill: ${theme.components.common.hightlightColor};
          }
        }
      }
    }
  `}
`

const CheckActions = styled.div`
  ${({ theme }) => theme.media.isMobile} {
    position: absolute;
    z-index: 2;
    background: ${({ theme }) => theme.colors.shades.white};
    padding: 4px;
    width: 180px;
  }
`

const ClearActions = styled(CheckActions)``

const UserAvatar = styled.img`
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 50%;
  vertical-align: bottom;
  margin-right: 16px;
`

const StyledUserIcon = styled(AccountIcon)`
  height: 24px;
  color: ${({ theme }) => theme.colors.shades.black};
`

const UserActions = styled.div`
  display: flex;
  align-items: center;
`

const UndoButton = styled.span`
  text-decoration: underline;
  font-weight: 300;
`

const SolvedButtons = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.media.isMobile} {
    flex-direction: column;
  }
`
