import styled from 'styled-components/macro'

type Props = {
  $align?: 'left' | 'center' | 'right'
}

export const ModalTitle = styled.div<Props>`
  margin: 0;
  padding: ${({ theme }) => theme.components.Modal.modalTitlePadding};
  text-align: ${({ $align }) => $align || 'left'};
  word-wrap: break-word;

  ${({ theme }) => theme.components.Modal.modalTitleStyle}
`
