import { memo } from 'react'
import styled from 'styled-components/macro'
import { NavHashLink } from 'src/components/links/NavHasLink'
import { useNavigation } from 'src/containers/libertatea/Header/useNavigation'
import { libertateaColors } from 'src/theme/themes/libertatea/colors'

export const NavigationMenu = memo(function NavigationMenu() {
  const { navLinks, onNavigationClick } = useNavigation()

  return (
    <Wrapper>
      {navLinks.map(
        ({ hash, title, to, isSeparatePage }) =>
          hash !== '#infoblock' && (
            <NavLink
              //@ts-ignore
              smooth
              key={`${hash}_link`}
              to={{ pathname: to, hash: hash }}
              selected={isSeparatePage ? window.location.pathname === to : window.location.hash === hash}
              onClick={() => onNavigationClick(title, hash)}
            >
              {title}
            </NavLink>
          ),
      )}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 0 0 0 62px;
`

const NavLink = styled(NavHashLink)<{ selected?: boolean }>`
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  text-transform: uppercase;
  color: ${({ selected }) => (selected ? libertateaColors.red : libertateaColors.greyDark)};
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  margin-right: 30px;

  &:hover {
    color: ${libertateaColors.red};
  }
`
