import { colors } from 'src/theme/colors'

export type TBetStyle = typeof defaultBet

export const defaultBet = {
  selectionHeaderBackground: colors.blick.grey20,
  selectedParticipantBackground: colors.blick.grey10,
  selectedParticipantBackgroundHover: colors.blick.grey10,
  infoBoxColor: colors.shades.white,
  infoBoxBackgroundColor: colors.blick.brandBg,
}
