import { Theme } from 'src/theme/styled'
import { defaultsDeep } from 'lodash-es'
import { theme as defaultTheme } from 'src/theme/themes/default'
import { ThemeNames } from 'src/theme/enums'
import { libertateaCommon } from './components/common'
import { libertateaButton } from './components/button'
import { libertateaLink } from './components/link'
import { libertateaInfoSection } from './components/infoSection'
import { libertateaHero } from './components/hero'
import { libertateaCollapsible } from './components/collapsible'
import { libertateaRules } from './components/rules'
import { libertateaDrawer } from './components/drawer'
import { libertateaUserDashBoard } from './components/userDashBoard'
import { libertateaModal } from './components/modal'
import { libertateaTextInput } from './components/textInput'
import { libertateaToast } from './components/toast'

const theme: Theme = defaultsDeep({}, defaultTheme)

theme.name = ThemeNames.LIBERTATEA
theme.components.common = libertateaCommon
theme.components.Button = libertateaButton
theme.components.InfoSection = libertateaInfoSection
theme.components.Hero = libertateaHero
theme.components.Collapsible = libertateaCollapsible
theme.components.Rules = libertateaRules
theme.components.Drawer = libertateaDrawer
theme.components.UserDashBoard = libertateaUserDashBoard
theme.components.Link = libertateaLink
theme.components.Modal = libertateaModal
theme.components.TextInput = libertateaTextInput
theme.components.Toast = libertateaToast

export const libertatea = theme
