import styled from 'styled-components/macro'
import { Blend } from 'src/components/skeleton/elements/Blend'
import { LineElement } from 'src/components/skeleton/elements/LineElement'
import { Skeleton } from 'src/components/skeleton/Skeleton'

export const RulesSkeleton = () => (
  <StyledSkeleton>
    <LineElement width="100%" height={48} />
    <Blend height={16} />
    <LineElement width="100%" height={48} />
    <Blend height={16} />
    <LineElement width="100%" height={48} />
    <Blend height={16} />
    <LineElement width="100%" height={48} />
    <Blend height={16} />
    <LineElement width="100%" height={48} />
  </StyledSkeleton>
)

const StyledSkeleton = styled(Skeleton)`
  padding: 0;
  border: none;
`
