import { components, DropdownIndicatorProps } from 'react-select'
import { ReactComponent as ChevronDownIcon } from 'src/assets/images/chevron_down.svg'

export const SelectDropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon />
    </components.DropdownIndicator>
  )
}
