import { TReduxState } from 'src/redux/store'
import { TCrossword } from './types'
import { TDateISODate } from 'src/utils/date/formatDate'
import { TSelectOption } from 'src/components/Select/types'

export const getLatestCrossword = (state: TReduxState): TCrossword | null => state.crosswords.data.latestCrossword

export const getCrosswords = (state: TReduxState): TCrossword[] | null => state.crosswords.data.crosswords
export const getCrosswordsArchive = (state: TReduxState): TCrossword[] | null =>
  state.crosswords.data.crosswordsArchive.crosswords
export const getCrosswordsArchiveFilter = (state: TReduxState): TSelectOption | undefined =>
  state.crosswords.data.crosswordsArchive.filter

export const getCrosswordsForDate =
  (date?: TDateISODate) =>
  (state: TReduxState): TCrossword | null | undefined =>
    state.crosswords.data.crosswords?.find((crossword) => crossword.day === date)
export const getFetchCrosswordsLoading = (state: TReduxState): boolean => state.crosswords.loading.fetchCrosswords

export const getCrosswordStateById = (id?: number) => (state: TReduxState) =>
  id ? state.crosswords.data.states[id] : null

export const getUpdateCrosswordStateLoading = (state: TReduxState): boolean =>
  state.crosswords.loading.updateCrosswordState
export const getUpdateCrosswordStateResolved = (state: TReduxState): boolean =>
  state.crosswords.resolved.updateCrosswordState

export const getCheckWordLoading = (state: TReduxState): boolean => state.crosswords.loading.checkWord
export const getCheckWordResolved = (state: TReduxState): boolean => state.crosswords.resolved.checkWord

export const getCheckCrosswordLoading = (state: TReduxState): boolean => state.crosswords.loading.checkCrossword
export const getCheckCrosswordResolved = (state: TReduxState): boolean => state.crosswords.resolved.checkCrossword

export const getCorrectWords = (state: TReduxState) => state.crosswords.data.correctWords
export const getIncorrectWords = (state: TReduxState) => state.crosswords.data.incorrectWords
