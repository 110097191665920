import { RefObject, useCallback, useEffect } from 'react'

export const useOutsideClick = (outsideClickHandler: () => void, ref: RefObject<HTMLElement | null>) => {
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (ref && ref.current && event.target && !ref.current.contains(event.target as HTMLElement)) {
        outsideClickHandler()
      }
    },
    [ref, outsideClickHandler],
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, handleClickOutside])
}
