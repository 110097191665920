import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { ReactComponent as ChevronDownIcon } from 'src/assets/images/chevron_down.svg'

type Props = {
  id?: string
  children: React.ReactNode
  title: string
  subtitle?: string
  closedByDefault?: boolean
  // onClickTracker?: ({ componentName, targetName, userSub }: TClickData) => void
  onClickTracker?: () => void
  opened?: boolean
}

export const Collapsible = ({
  id,
  children,
  title,
  subtitle,
  closedByDefault = false,
  onClickTracker,
  opened,
}: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(closedByDefault)
  // const userSub = useSelector(getUserSub)

  const onHeaderClick = () => {
    // TODO:GM
    // const eventPayload = {
    //   componentName: 'collapsible',
    //   targetName: title,
    //   userSub,
    // }
    // if (onClickTracker) {
    //   onClickTracker(eventPayload)
    // } else {
    //   trackOnClick(eventPayload)
    // }
    return setIsCollapsed(!isCollapsed)
  }

  useEffect(() => {
    if (opened !== undefined) {
      setIsCollapsed(!opened)
    }
  }, [opened])

  return (
    <Wrapper id={id}>
      <Header onClick={onHeaderClick} $isCollapsed={isCollapsed}>
        <div>
          <IconWrapper>{isCollapsed ? <ChevronDownIcon /> : <ChevronUpIcon />}</IconWrapper>
          <TextWrap>
            {title}
            {subtitle && (
              <>
                <Divider>|</Divider>
                <Subtitle>{subtitle}</Subtitle>
              </>
            )}
          </TextWrap>
        </div>
      </Header>
      {!isCollapsed && children}
    </Wrapper>
  )
}

const contentWidthStyle = css`
  width: ${({ theme }) => theme.measures.contentWidth};
  max-width: ${({ theme }) => theme.measures.contentWidth};
  box-sizing: border-box;

  ${({ theme }) => theme.media.isMobile} {
    max-width: 100%;
  }
`

const Wrapper = styled.div`
  background: ${({ theme }) => theme.components.Collapsible.contentBackground};
  border-radius: ${({ theme }) => theme.components.Collapsible.borderRadius};
  box-shadow: ${({ theme }) => theme.components.Collapsible.boxShadow};
  scroll-margin-top: ${({ theme }) => theme.components.common.scrollMarginTop};
`

const Header = styled.div<{ $isCollapsed: boolean }>`
  display: flex;
  padding: ${({ theme }) => theme.components.Collapsible.padding};
  cursor: pointer;
  background-color: ${({ theme }) => theme.components.Collapsible.background};
  ${({ theme }) => theme.components.Collapsible.headerTextStyle};
  border-radius: ${({ theme }) => theme.components.Collapsible.borderRadius};
  color: ${({ theme, $isCollapsed }) => {
    if (theme.components.Collapsible.collapsedColor && !$isCollapsed) {
      return theme.components.Collapsible.collapsedColor
    }
  }};
  > div {
    ${contentWidthStyle}
    display: flex;
    align-items: flex-start;
  }

  svg {
    color: ${({ theme, $isCollapsed }) => {
      if (theme.components.Collapsible.collapsedColor && !$isCollapsed) {
        return theme.components.Collapsible.collapsedColor
      }
      return theme.components.Collapsible.arrowColor
    }};
    width: ${({ theme }) => theme.components.Collapsible.arrowWidth};
    height: ${({ theme }) => theme.components.Collapsible.arrowHeight};
  }

  ${({ theme }) => theme.media.isMobile} {
    padding: 12px 16px;
  }

  ${({ $isCollapsed }) =>
    $isCollapsed &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.components.Collapsible.backgroundHover};
      }
    `}
`

const TextWrap = styled.div`
  width: 100%;
`

const Divider = styled.span`
  display: inline-block;
  margin-left: 8px;
  margin-right: 8px;
`

const Subtitle = styled.span`
  position: relative;
  font-weight: 400;
`

const IconWrapper = styled.div`
  margin-right: 16px;
`

const ChevronUpIcon = styled(ChevronDownIcon)`
  transform: Rotate(180deg);
`
