import styled from 'styled-components/macro'

import { pixelify } from 'src/utils/style/pixelify'
import { theme } from 'src/components/skeleton/theme'

type Props = {
  width?: string | number
  height?: string | number
  grow?: boolean
  shrink?: boolean
}

export const Blend = styled.div<Props>`
  min-height: ${({ height }) => (height ? pixelify(height) : '6px')};
  min-width: ${({ width }) => (width ? pixelify(width) : '100%')};
  background-color: ${theme.BACKGROUND_COLOR};
  ${({ grow }) => grow && 'flex-grow: 1;'}
  ${({ shrink }) => shrink && 'flex-shrink: 1;'}
`
