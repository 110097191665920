import { TModalStyle } from 'src/theme/themes/default/components/modal'
import { theme as defaultTheme } from 'src/theme/themes/default'

import { defaultsDeep } from 'lodash-es'

const modal: TModalStyle = defaultsDeep({}, defaultTheme.components.Modal)

modal.borderRadius = '0'
modal.baseFontWeight = 300
modal.padding = '16px'
modal.modalTitleStyle = `
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; 
`
export const libertateaModal = modal
