export type TCrosswordOfTheDayTranslations = typeof crosswordOfTheDayTranslations

export const crosswordOfTheDayTranslations = {
  title: '',
  description: <></>,
  ribbon: '',
  today: '',
  published: '',
  previous: '',
  previousDescription: '',
  loginButton: '',
  archiveButton: '',
  playButton: '',
  check: '',
  checkWord: '',
  checkCrossword: '',
  clear: '',
  clearWord: '',
  clearCrossword: '',
  clearCrosswordSuccess: '',
  backToStart: '',
  showMoreCrosswords: '',
  back: '',
  undo: '',
  shareSectionTitle: '',
  shareTitle: '',
  shareSubject: '',
  shareLinkCopied: '',
}
