import styled from 'styled-components/macro'
import ReactModal from 'react-modal'

import { useTheme } from 'src/theme/useTheme'
import { TTheme } from 'src/theme/themes/default'
import { CloseIcon, TCloseIconColor } from 'src/components/CloseIcon'

const getStyle = (theme: TTheme, width?: string): ReactModal.Styles => ({
  content: getContentStyle(theme, width),
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.4)',
    zIndex: theme.zIndex.modal,
  },
})

export const getContentStyle = (theme: TTheme, width?: string) => {
  return {
    width: 'calc(100% - 32px)',
    maxWidth: width || '560px',
    padding: theme.components.Modal.padding,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    borderRadius: theme.components.Modal.borderRadius,
    background: '#FFFFFF',
    boxShadow: '0px 1px 4px 1px rgba(0, 0, 0, 0.25)',
    boxSizing: 'border-box',
  } as React.CSSProperties
}

type Props = ReactModal.Props & {
  onRequestClose?: () => void
  onClose: () => void
  fullWidth?: boolean
  hideCloseButton?: boolean
  closeButtonColor?: TCloseIconColor
  isEdit?: boolean
  width?: string
}

export const Modal = ({
  children,
  isOpen,
  style,
  hideCloseButton,
  onRequestClose,
  onClose,
  width,
  closeButtonColor,
  ...rest
}: Props) => {
  const { theme } = useTheme()
  const defaultStyle = getStyle(theme, width)

  const combinedStyles: ReactModal.Styles = {
    content: {
      ...defaultStyle.content,
      ...style?.content,
    },
    overlay: {
      ...defaultStyle.overlay,
      ...style?.overlay,
    },
  }

  return (
    <ReactModal
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      {...rest}
      isOpen={isOpen}
      onRequestClose={onRequestClose || onClose}
      style={combinedStyles}
      appElement={document.getElementById('root') || undefined}
    >
      {!hideCloseButton && (
        <StyledCloseIcon
          $padding={style?.content?.padding ? `${style?.content?.padding}` : undefined}
          color={closeButtonColor}
          onClick={onRequestClose || onClose}
          size={18}
        />
      )}
      {children}
    </ReactModal>
  )
}

const StyledCloseIcon = styled(CloseIcon)<{ $padding?: string }>`
  position: absolute;
  right: ${({ theme, $padding }) => ($padding ? $padding : theme.components.Modal.padding)};
  top: ${({ theme, $padding }) => ($padding ? $padding : theme.components.Modal.padding)};
  cursor: pointer;
`
