import { AnchorHTMLAttributes, MouseEventHandler } from 'react'

type Props = AnchorHTMLAttributes<HTMLAnchorElement>

export const ExternalLinkDefault = ({ onClick, children, ...props }: Props) => {
  // const userSub = useSelector(getUserSub)

  const onLinkClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    // const buttonLabel = props['aria-label'] || getChildrenText(children)

    onClick && onClick(e)

    // trackExternalLinkClick(buttonLabel, props.href || '', userSub) // TODO:GA
  }

  return (
    <a {...props} onClick={onLinkClick}>
      {children}
    </a>
  )
}
