import styled from 'styled-components/macro'
import { components, ControlProps } from 'react-select'
import { TSelectOption } from 'src/components/Select/types'

export const SelectControl = ({ children, ...props }: ControlProps<TSelectOption>) => (
  <components.Control {...props}>
    {
      //@ts-expect-error
      props.selectProps.icon && <StyledImg src={props.selectProps.icon} alt={'icon'} />
    }
    {children}
  </components.Control>
)

const StyledImg = styled.img`
  padding-left: 16px;
  width: 24px;
  height: 24px;
  ${({ theme }) => theme.media.isMobile} {
    padding-left: 14px;
  }
`
