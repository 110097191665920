import { useMemo } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AppLayout } from 'src/AppLayout'
import { ThemeProvider } from 'styled-components/macro'

import { TranslationsProvider } from 'src/translations/TranslationsContext'
import { GlobalStyle } from 'src/components/GlobalStyle'
import { GlobalToastStyle } from 'src/components/toast/GlobalToastStyle'
import { ToastContainer } from 'src/components/toast/ToastContainer'
import { getTheme } from 'src/theme/utils'

export const App = () => {
  // const product = useSelector(getProduct)
  // const tenant = getTenant()
  // const isAuthResolved = useSelector(getIsAuthResolved)
  // const isLoggedIn = useSelector(getIsLoggedIn)

  // useEffect(() => {
  //   if ([Tenants.LIBERTATEA, 'libertatea2'].includes(tenant) && isAuthResolved) {
  //     pushSATIEvents(isLoggedIn)
  //   }
  // }, [isAuthResolved, isLoggedIn, tenant]) // TODO

  // const theme = useMemo(() => getTheme(product?.id), [product]) // TODO
  const theme = useMemo(() => getTheme(), [])

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <GlobalToastStyle />
      <BrowserRouter>
        <TranslationsProvider>
          <AppLayout />
        </TranslationsProvider>
      </BrowserRouter>
      <ToastContainer />
    </ThemeProvider>
  )
}
