import { Reducer } from 'redux'
import { getReduxFailureStates, getReduxFetchStates, getReduxResetStates, getReduxSuccessStates } from 'src/utils/redux'
import { ActionTypes } from './enums'
import { Actions, TCrosswordReduxState } from './types'
import { getWordPositionFromAnswer } from './utils'

export const initialState: TCrosswordReduxState = {
  data: {
    latestCrossword: null,
    crosswords: null,
    crosswordsArchive: { crosswords: null, filter: undefined },
    states: {},
    correctWords: [],
    incorrectWords: [],
    isCorrect: false,
  },
  loading: {
    TFetchLatestCrossword: false,
    fetchCrosswords: false,
    fetchCrosswordState: false,
    updateCrosswordState: false,
    checkWord: false,
    checkCrossword: false,
  },
  error: {
    TFetchLatestCrossword: null,
    fetchCrosswords: null,
    fetchCrosswordState: null,
    updateCrosswordState: null,
    checkWord: null,
    checkCrossword: null,
  },
  resolved: {
    TFetchLatestCrossword: false,
    fetchCrosswords: false,
    fetchCrosswordState: false,
    updateCrosswordState: false,
    checkWord: false,
    checkCrossword: false,
  },
}

export const reducer: Reducer<TCrosswordReduxState, Actions> = (state = initialState, action): TCrosswordReduxState => {
  switch (action.type) {
    case ActionTypes.FETCH_LATEST_CROSSWORD: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'TFetchLatestCrossword'),
      }
    }
    case ActionTypes.FETCH_LATEST_CROSSWORD_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          latestCrossword: action.payload.crosswords[0],
        },
        ...getReduxSuccessStates(state, 'TFetchLatestCrossword'),
      }
    }
    case ActionTypes.FETCH_LATEST_CROSSWORD_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'TFetchLatestCrossword', action.payload),
      }
    }

    case ActionTypes.FETCH_CROSSWORDS: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'fetchCrosswords'),
      }
    }
    case ActionTypes.FETCH_CROSSWORDS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          crosswords: action.payload.crosswords,
        },
        ...getReduxSuccessStates(state, 'fetchCrosswords'),
      }
    }
    case ActionTypes.FETCH_CROSSWORDS_ARCHIVE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          crosswordsArchive: {
            ...state.data.crosswordsArchive,
            crosswords: action.payload.crosswords,
          },
        },
        ...getReduxSuccessStates(state, 'fetchCrosswords'),
      }
    }
    case ActionTypes.FETCH_CROSSWORDS_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchCrosswords', action.payload),
      }
    }
    case ActionTypes.SET_CROSSWORDS_ARCHIVE_FILTER: {
      return {
        ...state,
        data: {
          ...state.data,
          crosswordsArchive: {
            ...state.data.crosswordsArchive,
            filter: action.payload,
          },
        },
        ...getReduxSuccessStates(state, 'fetchCrosswords'),
      }
    }

    case ActionTypes.FETCH_CROSSWORD_STATE: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'fetchCrosswordState'),
      }
    }
    case ActionTypes.FETCH_CROSSWORD_STATE_SUCCESS: {
      const { id } = action.payload

      return {
        ...state,
        data: {
          ...state.data,
          states: {
            ...state.data.states,
            [id]: action.payload,
          },
        },
        ...getReduxSuccessStates(state, 'fetchCrosswordState'),
      }
    }
    case ActionTypes.FETCH_CROSSWORD_STATE_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchCrosswordState', action.payload),
      }
    }
    case ActionTypes.CLEAN_FETCH_CROSSWORD_STATE_STATES: {
      return {
        ...state,
        ...getReduxResetStates(state, initialState, ['fetchCrosswordState']),
      }
    }

    case ActionTypes.UPDATE_CROSSWORD_STATE: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'updateCrosswordState'),
      }
    }
    case ActionTypes.UPDATE_CROSSWORD_STATE_SUCCESS: {
      const { id } = action.payload

      return {
        ...state,
        data: {
          ...state.data,
          states: {
            ...state.data.states,
            [id]: action.payload,
          },
        },
        ...getReduxSuccessStates(state, 'updateCrosswordState'),
      }
    }
    case ActionTypes.UPDATE_CROSSWORD_STATE_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'updateCrosswordState', action.payload),
      }
    }
    case ActionTypes.CLEAN_UPDATE_CROSSWORD_STATE_STATES: {
      return {
        ...state,
        ...getReduxResetStates(state, initialState, ['updateCrosswordState']),
      }
    }

    case ActionTypes.CHECK_WORD: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'checkWord'),
      }
    }
    case ActionTypes.CHECK_WORD_SUCCESS: {
      const correctWords = state.data.correctWords.slice()
      const incorrectWords = []
      const wordPosition = {
        down: action.payload.down,
        across: action.payload.across,
        direction: action.payload.direction,
        number: action.payload.number,
      }
      if (action.payload.correct) {
        correctWords.push(wordPosition)
      } else {
        incorrectWords.push(wordPosition)
      }
      return {
        ...state,
        data: {
          ...state.data,
          correctWords,
          incorrectWords,
        },
        ...getReduxSuccessStates(state, 'checkWord'),
      }
    }
    case ActionTypes.CHECK_WORD_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'checkWord', action.payload),
      }
    }
    case ActionTypes.CLEAN_CHECK_WORD_STATES: {
      return {
        ...state,
        ...getReduxResetStates(state, initialState, ['checkWord']),
      }
    }

    case ActionTypes.CHECK_CROSSWORD: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'checkCrossword'),
      }
    }
    case ActionTypes.CHECK_CROSSWORD_SUCCESS: {
      const { id, guesses, isCompletelySolved, correct, invalid } = action.payload
      const correctWords = getWordPositionFromAnswer(correct, guesses)
      const incorrectWords = getWordPositionFromAnswer(invalid, guesses)

      return {
        ...state,
        data: {
          ...state.data,
          correctWords,
          incorrectWords,
          states: {
            ...state.data.states,
            [id]: {
              ...state.data.states[id],
              isSolved: isCompletelySolved,
            },
          },
        },
        ...getReduxSuccessStates(state, 'checkCrossword'),
      }
    }
    case ActionTypes.CHECK_CROSSWORD_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'checkCrossword', action.payload),
      }
    }
    case ActionTypes.CLEAN_CHECK_CROSSWORD_STATES: {
      return {
        ...state,
        ...getReduxResetStates(state, initialState, ['checkCrossword']),
      }
    }

    case ActionTypes.CLEAR_CORRECT_INCORRECT_WORDS: {
      return {
        ...state,
        data: {
          ...state.data,
          correctWords: initialState.data.correctWords,
          incorrectWords: initialState.data.incorrectWords,
        },
      }
    }

    case ActionTypes.CLEAR_INCORRECT_WORDS: {
      return {
        ...state,
        data: {
          ...state.data,
          incorrectWords: [],
        },
      }
    }

    case ActionTypes.CLEAR_FROM_INCORRECT_WORDS: {
      return {
        ...state,
        data: {
          ...state.data,
          incorrectWords: state.data.incorrectWords.filter((word) => word.number !== action.payload.number),
        },
      }
    }

    default:
      return state
  }
}
