import { TCrosswordOfTheDayTranslations } from 'src/translations/de/default/crosswordOfTheDay'

export const crosswordOfTheDayTranslations: TCrosswordOfTheDayTranslations = {
  title: 'Rebusul zilei',
  description: (
    <>
      Completează corect rebusul în fiecare zi și câștigi <b>10 puncte</b> cu care poți revendica premii importante!
    </>
  ),
  ribbon: 'Joc nou',
  today: 'Astăzi',
  published: 'Publicat',
  previous: 'Rebusuri anterioare',
  previousDescription: 'Descoperă toate rebusurile publicate',
  loginButton: 'AUTENTIFICĂ-TE ȘI JOACĂ',
  archiveButton: 'Accesează arhiva',
  playButton: 'Joacă',
  check: 'Verifică',
  checkWord: 'Verifică cuvântul',
  checkCrossword: 'Verifică rebusul',
  clear: 'Șterge',
  clearWord: 'Șterge cuvântul',
  clearCrossword: 'Resetează rebusul',
  clearCrosswordSuccess: 'Rebusul a fost resetat',
  backToStart: 'Înapoi la start',
  showMoreCrosswords: 'Arată mai multe rebusuri',
  back: 'Înapoi',
  undo: 'Anulează',
  shareSectionTitle: 'Distribuie jocul cu prietenii tăi',
  shareTitle:
    'Rezolvă zilnic jocurile de cuvinte încrucișate din Libertatea. Câștigă puncte pentru fiecare rebus și revendica premiile din platforma de Concurs',
  shareSubject: 'Rezolvă cuvintele încrucișate pe site-ul Libertatea',
  shareLinkCopied: 'Am copiat link-ul',
}
