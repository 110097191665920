export type TModalsTranslations = typeof modalsTranslations

export const modalsTranslations = {
  confirmClear: {
    title: '',
    no: '',
    yes: '',
  },
  stillWrong: {
    title: '',
    keepTrying: '',
  },
  solved: {
    title: '',
    subtitle: '',
    share: '',
    close: '',
  },
}
