import { TRulesTranslations } from 'src/translations/de/default/rules'
import { ExternalLink } from 'src/components/links/ExternalLink'

export const rulesTranslations: TRulesTranslations = {
  title: 'FAQ',
  description: (
    <>
      Pentru mai multe detalii, consultă regulamentul concursului, accesează rubrica FAQ de mai jos sau trimite-ne un
      e-mail la{' '}
      <ExternalLink href="mailto:intrebareazilei@ringier.ro" target="_blank" key="link-1">
        intrebareazilei@ringier.ro
      </ExternalLink>
      .
    </>
  ),
}
