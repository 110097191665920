import { applyMiddleware } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'

import { rootReducer } from './rootReducer'
// import { trackingMiddleware } from 'src/tracking/gtm/trackingMiddleware'

// setup middlewares
// TO DO -> GTM tracking middleware
const enhancers = applyMiddleware(thunk)

export const store = configureStore({ reducer: rootReducer, enhancers: [enhancers] })

// @ts-ignore
window.__STORE = store

export type TReduxState = ReturnType<typeof store.getState>
export type TAppDispatch = typeof store.dispatch

export const setupStore = () => ({ store })
