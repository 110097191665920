import { useContext } from 'react'
import { CrosswordSizeContext } from './context'
import { UnusedCellData } from './types'

type Props = {
  cellData: UnusedCellData
}

export const UnusedCell = ({ cellData }: Props) => {
  const { cellSize, cellPadding, cellInner } = useContext(CrosswordSizeContext)

  const x = cellData.col * cellSize
  const y = cellData.row * cellSize

  return (
    <g>
      <rect
        x={x + cellPadding}
        y={y + cellPadding}
        width={cellInner}
        height={cellInner}
        fill={`rgb(51, 51, 51)`}
        // stroke={theme.components.Crossword.cellBorder}
        strokeWidth={cellSize / 50}
        rx="0.5"
      />
    </g>
  )
}
