import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'

import type { Direction } from './types'
import { CrosswordContext } from './context'
import { useTheme } from 'src/theme/useTheme'

interface ClueWrapperProps {
  $complete?: boolean | null
  $correct?: boolean | null
  $highlight?: boolean | null // TODO: Remove
  $highlightBackground?: string | null // TODO: Remove
  $focusBackground?: string | null // TODO: Remove
}

const ClueWrapper = styled.div.attrs<ClueWrapperProps>((props) => ({
  className: `clue${props.$complete ? (props.$correct ? ' correct' : ' incorrect') : ''}`,
}))<ClueWrapperProps>`
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  line-height: 140%;
  background-color: ${({ $highlight, $highlightBackground }) => ($highlight ? $highlightBackground : 'transparent')};
  ${({ $complete, $highlight }) =>
    $complete &&
    !$highlight &&
    css`
      color: #9c9c9c;
    `};

  padding-left: 16px;
  position: relative;
  min-height: 24px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  font-weight: ${({ $highlight }) => ($highlight ? '700' : '300')};

  &:hover {
    background: ${({ $highlightBackground }) => $highlightBackground};
  }

  &:after {
    content: '';
    display: ${({ $highlight }) => ($highlight ? 'block' : 'none')};
    height: 100%;
    width: 8px;
    background: ${({ $focusBackground }) => $focusBackground};
    position: absolute;
    top: 0;
    left: 0;
  }
`

/**
 * Renders an individual clue, with its number. Makes use of `CrosswordContext`
 * to know whether to render as “highlighted” or not, and uses the theme to
 * provide the highlighting color.
 */
export const Clue = ({ direction, number, children, complete, correct, ...props }: Props) => {
  const { focused, selectedDirection, selectedNumber, handleClueSelected } = useContext(CrosswordContext)
  const { theme } = useTheme()

  const handleClick = useCallback<React.MouseEventHandler>(
    (event) => {
      event.preventDefault()
      handleClueSelected(direction, number)
    },
    [direction, number, handleClueSelected],
  )

  return (
    <ClueWrapper
      $highlightBackground={theme.components.Crossword.highlightBackground}
      $focusBackground={theme.components.Crossword.focusBackground}
      $highlight={focused && direction === selectedDirection && number === selectedNumber}
      $complete={complete}
      $correct={correct}
      {...props}
      onClick={handleClick}
      aria-label={`clue-${number}-${direction}`}
    >
      {`${number}) ${children}`}
    </ClueWrapper>
  )
}

Clue.propTypes = {
  /** direction of the clue: "across" or "down"; passed back in onClick */
  direction: PropTypes.string.isRequired,
  /** number of the clue (the label shown); passed back in onClick */
  number: PropTypes.string.isRequired,
  /** clue text */
  children: PropTypes.node.isRequired,
  /** whether the answer/guess is complete */
  complete: PropTypes.bool,
  /** whether the answer/guess is correct */
  correct: PropTypes.bool,
}

Clue.defaultProps = {
  // children: undefined,
  complete: undefined,
  correct: undefined,
}

type Props = {
  direction: Direction
  number: string
  children: React.ReactNode
  complete: boolean
  correct: boolean
}
