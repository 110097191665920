import { Reducer } from 'redux'

import { TActions, IAuthState } from './types'
import { ActionTypes } from './enums'
// import { ActionTypes as MarketingActionTypes } from 'src/api/marketing/enums'
// import { TPostOptinSuccessAction } from 'src/api/marketing/types'

const initialState: IAuthState = {
  user: null,
  metaData: null,
  loggedIn: false,
  authResolved: false,
  isLoading: true,
  showNicknameModal: false,
  updateNickname: {
    isLoading: false,
    error: null,
    isResolved: false,
  },
}

// export const reducer: Reducer<IAuthState, TActions | TPostOptinSuccessAction> = (
export const reducer: Reducer<IAuthState, TActions> = (state = initialState, action): IAuthState => {
  switch (action.type) {
    case ActionTypes.AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        metaData: action.payload.metaData || null,
        loggedIn: true,
        authResolved: true,
      }
    case ActionTypes.AUTH_LOGOUT: {
      return {
        ...initialState,
        authResolved: true,
      }
    }
    case ActionTypes.AUTH_CHECK_START: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case ActionTypes.AUTH_CHECK_FINISH: {
      return {
        ...state,
        isLoading: false,
        authResolved: true,
      }
    }
    case ActionTypes.AUTH_UPDATE_NICKNAME: {
      return {
        ...state,
        updateNickname: {
          ...state.updateNickname,
          isLoading: true,
          isResolved: initialState.updateNickname.isResolved,
          error: null,
        },
      }
    }
    case ActionTypes.AUTH_UPDATE_NICKNAME_SUCCESS: {
      return {
        ...state,
        user: state.user && { ...state.user, nickname: action.payload.nickname, gamePlatformAgreementSigned: true },
        updateNickname: { ...state.updateNickname, isLoading: initialState.updateNickname.isLoading, isResolved: true },
      }
    }
    case ActionTypes.AUTH_UPDATE_NICKNAME_FAILURE: {
      return {
        ...state,
        updateNickname: {
          ...state.updateNickname,
          isLoading: initialState.updateNickname.isLoading,
          error: action.payload,
        },
      }
    }
    case ActionTypes.AUTH_RESET_NICKNAME_ERROR: {
      return {
        ...state,
        updateNickname: { ...state.updateNickname, error: initialState.updateNickname.error },
      }
    }
    case ActionTypes.AUTH_SHOW_REQ_MODAL: {
      return {
        ...state,
        showNicknameModal: true,
      }
    }
    case ActionTypes.AUTH_HIDE_REQ_MODAL: {
      return {
        ...state,
        showNicknameModal: false,
      }
    }
    // case MarketingActionTypes.POST_OPTIN_SUCCESS: {
    //   return {
    //     ...state,
    //     user: state.user
    //       ? {
    //           ...state.user,
    //           marketingOptin: action.payload.marketingOptin,
    //         }
    //       : null,
    //   }
    // }
    default:
      return state
  }
}
