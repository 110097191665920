export type TUserDashBoardTranslations = typeof userDashBoardTranslations

export const userDashBoardTranslations = {
  currentMonth: 'Current Month',
  moreGames: 'Discover more games',
  myAccount: 'Mein Konto',
  logout: 'Abmelden',
  completedCorrectly: '',
  pointsEarned: '',
  totalPoints: '',
}
