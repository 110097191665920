import { HashLink, HashLinkProps } from 'react-router-hash-link'
import styled, { css } from 'styled-components/macro'
import { buttonTheme, buttonStyles, TButtonTheme } from 'src/components/buttons/Button'

type Props = HashLinkProps & {
  componentTheme?: TButtonTheme
  fullWidth?: boolean
  disabled?: boolean
  borderRadius?: string
}

export const InternalLinkButton = ({ children, ...restProps }: Props) => {
  return <InternalLinkButtonElement {...restProps}>{children}</InternalLinkButtonElement>
}

const InternalLinkButtonElement = styled(({ componentTheme, fullWidth, disabled, borderRadius, ...rest }: Props) => (
  <HashLink {...rest} />
))`
  ${buttonStyles}
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}
  ${({ componentTheme }) => buttonTheme[componentTheme || 'primary']}
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}`}
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`
