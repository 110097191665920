import { useDispatch } from 'react-redux'
import { generatePath, useNavigate } from 'react-router-dom'
import { logout } from 'src/api/auth/actions'
import { isFromPaths } from 'src/api/utils/isFromPaths'
import { config } from 'src/config'
import { Path } from 'src/router/enums'
import { getProductFromPath } from 'src/utils/auth'

export const useDashBoardFooterActions = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const productId = getProductFromPath()

  const onClicкGameCenter = () => {
    window.location.href = config.externalLinks.libertatea.gamecenter
  }

  const onClickProfile = () => {
    window.location.href = config.externalLinks.libertatea.myAccount
  }
  const onClickLogout = () => {
    const isGameUrl = isFromPaths([Path.CrossowordForDay], window.location.pathname)
    const landingPath = generatePath(Path.Product, { productName: productId || '' })
    const targetUrl = isGameUrl ? window.location.origin + landingPath : window.location.href

    if (isGameUrl) {
      navigate(landingPath)
    }

    dispatch(logout({ returnUrl: targetUrl }))
  }

  return {
    onClicкGameCenter,
    onClickLogout,
    onClickProfile,
  }
}
