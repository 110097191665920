import { ComponentProps, useContext } from 'react'
import { Spacer } from 'src/components/Spacer'
import { Button } from 'src/components/buttons/Button'
import { Modal, ModalButtonsWrapper, ModalTitle } from 'src/components/modal'
import { TranslationsContext } from 'src/translations/TranslationsContext'
import { useMedia } from 'src/utils/media/useMedia'
import styled from 'styled-components/macro'

type Props = ComponentProps<typeof Modal> & {
  onConfirm: () => void
}

export const ConfirmClearModal = ({ isOpen, onClose, onConfirm }: Props) => {
  const translations = useContext(TranslationsContext)
  const { isMobile } = useMedia()

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalTitle>{translations.modals.confirmClear.title}</ModalTitle>
      <StyledModalButtonsWrapper>
        <Button fullWidth={isMobile} componentTheme="secondary" onClick={onClose}>
          {translations.modals.confirmClear.no}
        </Button>
        <Spacer $h={!isMobile} $v={isMobile} size={16} />
        <Button fullWidth={isMobile} componentTheme="primary" onClick={onConfirm}>
          {translations.modals.confirmClear.yes}
        </Button>
      </StyledModalButtonsWrapper>
    </Modal>
  )
}

const StyledModalButtonsWrapper = styled(ModalButtonsWrapper)`
  ${({ theme }) => theme.media.isMobile} {
    flex-direction: column-reverse;
  }
`
