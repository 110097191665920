export enum ActionTypes {
  AUTH_LOGIN = '@AUTH/AUTH_LOGIN',
  AUTH_LOGIN_SUCCESS = '@AUTH/AUTH_LOGIN_SUCCESS',
  AUTH_LOGOUT = '@AUTH/AUTH_LOGOUT',
  AUTH_CHECK_START = '@AUTH/AUTH_CHECK_START',
  AUTH_CHECK_FINISH = '@AUTH/AUTH_CHECK_FINISH',
  AUTH_UPDATE_NICKNAME = '@AUTH/AUTH_UPDATE_NICKNAME',
  AUTH_UPDATE_NICKNAME_SUCCESS = '@AUTH/AUTH_UPDATE_NICKNAME_SUCCESS',
  AUTH_UPDATE_NICKNAME_FAILURE = '@AUTH/AUTH_UPDATE_NICKNAME_FAILURE',
  AUTH_RESET_NICKNAME_ERROR = '@AUTH/AUTH_RESET_NICKNAME_ERROR',
  AUTH_SHOW_REQ_MODAL = '@AUTH/AUTH_SHOW_REQ_MODAL',
  AUTH_HIDE_REQ_MODAL = '@AUTH/AUTH_HIDE_REQ_MODAL',
}
