import styled from 'styled-components/macro'

import { ReactComponent as ArrowIcon } from 'src/assets/images/chevron_down.svg'
import { ReactComponent as AccountIcon } from 'src/assets/images/user_icon.svg'
import { libertateaColors } from 'src/theme/themes/libertatea/colors'
import { getUserAvatarUrl } from 'src/api/auth/selectors'
import { useSelector } from 'react-redux'
// import { getUserAvatarUrl } from 'src/api/auth/selectors'

interface Props {
  onClick: () => void
  userNickName: string | undefined
}

const AVATAR_SIZE = 24

export const UserInfoBlock = ({ onClick, userNickName }: Props) => {
  const userAvatarUrl = useSelector(getUserAvatarUrl)

  return (
    <InfoBlock onClick={onClick}>
      <IconWrapper>{userAvatarUrl ? <UserAvatar src={userAvatarUrl} /> : <StyledUserIcon />}</IconWrapper>
      <UserName>{userNickName}</UserName>
      <StyledArrowIcon />
    </InfoBlock>
  )
}

const UserAvatar = styled.img`
  margin: 0 auto 8px;
  width: ${AVATAR_SIZE}px;
  height: ${AVATAR_SIZE}px;
  object-fit: cover;
  border-radius: 50%;
  vertical-align: bottom;
`

const IconWrapper = styled.div`
  width: ${AVATAR_SIZE}px;
  height: ${AVATAR_SIZE}px;
  margin-right: 8px;
`

const StyledUserIcon = styled(AccountIcon)`
  height: 24px;
  color: ${({ theme }) => theme.colors.shades.black};
`

const InfoBlock = styled.button`
  display: flex;
  align-items: center;
  padding: 8px;
  padding-right: 16px;
  font-family: inherit;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.shades.black};
  color: ${({ theme }) => theme.colors.shades.black};
  border-radius: 20px;
  cursor: pointer;
  ${({ theme }) => theme.media.isMobile} {
    max-width: calc(100% - 16px);
    padding: 4px;
    padding-right: 8px;
  }
  &:hover {
    border: 1px solid ${libertateaColors.red};
    color: ${libertateaColors.red};
    ${StyledUserIcon} {
      color: ${libertateaColors.red};
    }
  }
`

const UserName = styled.div`
  position: relative;
  font-size: 18px;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: 0.01em;
  width: calc(100%);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 14px;
  ${({ theme }) => theme.media.isMobile} {
    font-size: 16px;
    margin-right: 10px;
  }
`
const StyledArrowIcon = styled(ArrowIcon)`
  width: 12px;
  min-width: 12px;
`
