import { defaultCommon } from './common'
import { defaultButton } from './button'
import { defaultLink } from './link'
import { defaultTab } from './tab'
import { defaultTable } from './table'
import { defaultCollapsible } from './collapsible'
import { defaultToast } from './toast'
import { defaultInfoSection } from './infoSection'
import { defaultRules } from './rules'
import { defaultHero } from './hero'
import { defaultBet } from './bet'
import { defaultDrawer } from './drawer'
import { defaultUserDashBoard } from './userDashBoard'
import { defaultModal } from './modal'
import { defaultTextInput } from './textInput'
import { defaultHeader } from './header'
import { defaultCrossword } from './crossword'

export const components = {
  common: defaultCommon,
  Header: defaultHeader,
  Button: defaultButton,
  Link: defaultLink,
  Tab: defaultTab,
  Table: defaultTable,
  Collapsible: defaultCollapsible,
  Toast: defaultToast,
  InfoSection: defaultInfoSection,
  Rules: defaultRules,
  Hero: defaultHero,
  Bet: defaultBet,
  Drawer: defaultDrawer,
  UserDashBoard: defaultUserDashBoard,
  Modal: defaultModal,
  TextInput: defaultTextInput,
  Crossword: defaultCrossword,
}
