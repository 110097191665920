import { translations } from 'src/translations/de/default'
import { defaultsDeep } from 'lodash-es'

import { rulesTranslations } from './rules'
import { userDashBoardTranslations } from './userDashBoard'
import { navigationLinksTranslations } from './navigationLinks'
import { TLocale } from 'src/utils/date/types'

export const enTranslations = defaultsDeep({}, translations)

enTranslations.rules = rulesTranslations
enTranslations.userDashBoard = userDashBoardTranslations
enTranslations.navigationLinks = navigationLinksTranslations
enTranslations.dateLocale = TLocale.EN
