import dayjs from 'dayjs'
import { TLocale } from './types'

export enum DateFormat {
  YEAR_MONTH,
  YEAR_MONTH_ALT,
  YEAR_MONTH_DAY,
  DAY_MONTH_YEAR,
  YEAR_MONTH_DAY_ALT,
  DAY_NAME,
}

const formats = {
  [DateFormat.YEAR_MONTH]: 'YYYY-MM',
  [DateFormat.YEAR_MONTH_ALT]: 'MMMM YYYY',
  [DateFormat.YEAR_MONTH_DAY]: 'YYYY-MM-DD',
  [DateFormat.DAY_MONTH_YEAR]: 'DD.MM.YYYY',
  [DateFormat.YEAR_MONTH_DAY_ALT]: 'YYYY.MM.DD',
  [DateFormat.DAY_NAME]: 'dddd',
}

export const formatDate = (date: DateType, dateFormat: DateFormat, locale?: TLocale) => {
  const dayjsDate = dayjs(date)
  if (locale) {
    dayjsDate.locale(locale)
  }
  return dayjsDate.format(formats[dateFormat])
}

// ISO date type
type TYear = `${number}${number}${number}${number}`
type TMonth = `${number}${number}`
type TDay = `${number}${number}`
type THours = `${number}${number}`
type TMinutes = `${number}${number}`
type TSeconds = `${number}${number}`
type TMilliseconds = `${number}${number}${number}`

export type TDateISODate = `${TYear}-${TMonth}-${TDay}`
export type TDateISOTime = `${THours}:${TMinutes}:${TSeconds}.${TMilliseconds}`
export type TDateISO = `${TDateISODate}T${TDateISOTime}Z`
