import { combineReducers } from 'redux'

import { reducer as rules } from 'src/api/rules/reducer'
import { reducer as crosswords } from 'src/api/crosswords/reducer'
import { reducer as app } from 'src/state/app/reducer'
import { reducer as router } from 'src/state/router/reducer'
import { reducer as auth } from 'src/api/auth/reducer'
import { reducer as user } from 'src/api/user/reducer'

export const rootReducer = combineReducers({ app, router, auth, rules, crosswords, user })
