export const libertateaMeasures = {
  contentWidth: '994px',
  navigation: {
    height: '113px',
    heightMobile: '60px',
    miniHeaderHeight: '58px',
    headerHeightDesktop: '163px',
    headerHeightMobile: '102px',
    navigationScrollThreshold: '50px',
  },
}
