import { createGlobalStyle } from 'styled-components/macro'
import 'react-toastify/dist/ReactToastify.css'

import { TOAST_CLASSNAMES, CSS_SELECTORS, MOBILE_BOTTOM_MARGIN } from './constants'

// Override the default toast style
// Make sure to ONLY change visuals, no positioning
export const GlobalToastStyle = createGlobalStyle`
  ${CSS_SELECTORS.Container} {
    min-width: 320px;
    ${({ theme }) => theme.media.isMobile} {
      top: 16px;

      padding: 0 ${({ theme }) => theme.measures.section.paddingHorizontal};
      margin: 0 auto;

      width: 100%;
      max-width: 100%;
    }

    ${({ theme }) => theme.media.isDesktop} {
      top: 24px;
      padding: 4px ${({ theme }) => theme.measures.section.paddingHorizontal};

      width: auto;
      max-width: ${({ theme }) => theme.measures.contentWidth};
    }
  }

  ${CSS_SELECTORS.Toast} {
    min-height: 0px;
    min-width: 320px;
    max-width: 100%;
    width: max-content; 

    padding: 16px;
    margin-left: auto;
    margin-right: auto;

    ${({ theme }) => theme.media.isMobile} {
      margin-bottom: ${MOBILE_BOTTOM_MARGIN};
    }

    font-family: ${({ theme }) => theme.components.common.font};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.components.Toast.defaultColor};
    border-radius: ${({ theme }) => theme.components.Toast.defaultBorderRadius};
    border: 1px solid ${({ theme }) => theme.components.Toast.defaultBorderColor};
    background-color: ${({ theme }) => theme.components.Toast.defaultBackgroundColor};
    &.${TOAST_CLASSNAMES.success} {
      background-color: ${({ theme }) => theme.components.Toast.successBackgroundColor};
      border: 1px solid ${({ theme }) => theme.components.Toast.successBorderColor};
      color: ${({ theme }) => theme.colors.shades.white};
      ${CSS_SELECTORS.Icon} { 
        margin-top: 1px;
      }
    }
    &.${TOAST_CLASSNAMES.warning} {
      background-color: ${({ theme }) => theme.components.Toast.warningBackgroundColor};
      border: 1px solid ${({ theme }) => theme.components.Toast.warningBorderColor};
    }
    &.${TOAST_CLASSNAMES.error} {
      background-color: ${({ theme }) => theme.components.Toast.errorBackgroundColor};
      border: 1px solid ${({ theme }) => theme.components.Toast.errorBorderColor};
    }
  }

  ${CSS_SELECTORS.Body} {
    padding: 0;
    padding-right: 90px;
    margin: 0;
    background: transparent;
    align-items: start;
    ${({ theme }) => theme.media.isMobile} {
      padding-right: 8px;
    }
  }

  ${CSS_SELECTORS.CloseButton} {
    top: 0;
    right: 0;

    color: ${({ theme }) => theme.colors.shades.white};
    background-color: ${({ theme }) => theme.colors.shades.shade15};
    opacity: 1 !important;
  }

  ${CSS_SELECTORS.CloseButtonSVGs} {
    fill: currentColor;
  }

  ${CSS_SELECTORS.Icon} {
    margin-top: 2px;
    height: 18px;
    width: 18px;
  }

`
