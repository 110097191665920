import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'

import { CrosswordSizeContext } from './context'
import type { UsedCellData, EnhancedProps } from './types'
import { useTheme } from 'src/theme/useTheme'
import correctWordIcon from 'src/assets/images/correct_word_icon.svg'
import wrongWordIcon from 'src/assets/images/wrong_word_icon.svg'
import { libertatea } from 'src/theme/themes/libertatea'

const cellPropTypes = {
  /** the data specific to this cell */
  cellData: PropTypes.shape({
    row: PropTypes.number.isRequired,
    col: PropTypes.number.isRequired,
    guess: PropTypes.string, // .isRequired,
    number: PropTypes.string,
    answer: PropTypes.string,
  }).isRequired,

  /** whether this cell has focus */
  focus: PropTypes.bool,

  /** whether this cell is highlighted */
  highlight: PropTypes.bool,

  /** handler called when the cell is clicked */
  onClick: PropTypes.func,
}

export type CellProps = EnhancedProps<
  typeof cellPropTypes,
  {
    /** the data specific to this cell */
    cellData: UsedCellData
    /** handler called when the cell is clicked */
    onClick?: (cellData: UsedCellData) => void
  }
>

/**
 * An individual-letter answer cell within the crossword grid.
 *
 * A `Cell` lives inside the SVG for a
 * [`CrosswordGrid`](#/Complex%20layouts/CrosswordGrid), and renders at a
 * position determined by the `row`, `col`, and `cellSize` properties from
 * `cellData` and `renderContext`.
 */
export const Cell = ({ cellData, onClick, focus, highlight }: CellProps) => {
  const { cellSize, cellPadding, cellInner, cellHalf, fontSize } = useContext(CrosswordSizeContext)
  const { theme } = useTheme()

  const handleClick = useCallback<React.MouseEventHandler>(
    (event) => {
      event.preventDefault()
      if (onClick) {
        onClick(cellData)
      }
    },
    [cellData, onClick],
  )

  const { row, col, guess, number, answer } = cellData

  const x = col * cellSize
  const y = row * cellSize

  const getFill = () => {
    if (focus) {
      return theme.components.Crossword.focusBackground
    }

    if (highlight) {
      return theme.components.Crossword.highlightBackground
    }

    return theme.components.Crossword.cellBackground
  }

  return (
    <g onClick={handleClick} style={{ cursor: 'default', fontSize: `${fontSize}px` }} className="clue-cell">
      <rect
        x={x + cellPadding}
        y={y + cellPadding}
        width={cellInner}
        height={cellInner}
        fill={getFill()}
        // stroke={theme.components.Crossword.cellBorder}
        strokeWidth={cellSize / 50}
        rx="0.5"
      />
      {number && (
        <text
          x={x + cellPadding * 4}
          y={y + cellPadding * 4}
          textAnchor="start"
          dominantBaseline="hanging"
          style={{ fontSize: '30%', fill: theme.components.Crossword.numberColor }}
        >
          {number}
        </text>
      )}
      <text
        x={x + cellHalf - 0.3}
        y={y + cellHalf + 1} // +1 for visual alignment?
        textAnchor="middle"
        dominantBaseline="middle"
        style={{
          fill: theme.components.Crossword.textColor,
          fontFamily: libertatea.components.common.font,
          color: theme.components.Crossword.textColor,
          fontWeight: 'bold',
          fontSize: '80%',
        }}
        className={answer === guess ? 'guess-text-correct' : 'guess-text-incorrect'}
      >
        {guess}
      </text>
      {cellData.correctHighlight && (
        <image
          x={x + cellHalf + cellHalf - 3}
          y={y + cellPadding + 0.2}
          width="2.2"
          height="2.2"
          href={correctWordIcon}
        />
      )}
      {cellData.incorrectHighlight && !cellData.correctHighlight && (
        <image x={x + cellPadding} y={y + cellPadding} width={cellInner} height={cellInner} href={wrongWordIcon} />
      )}
    </g>
  )
}

Cell.propTypes = cellPropTypes

Cell.defaultProps = {
  focus: false,
  highlight: false,
  onClick: null,
}
