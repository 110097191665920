import { translations } from 'src/translations/de/default'
import { defaultsDeep } from 'lodash-es'
import { TLocale } from 'src/utils/date/types'

import { navigationLinksTranslations } from './navigationLinks'
import { userDashBoardTranslations } from './userDashBoard'
import { rulesTranslations } from './rules'
import { crosswordOfTheDayTranslations } from './crosswordOfTheDay'
import { archiveTranslations } from './archive'
import { modalsTranslations } from './modals'

export const roTranslations = defaultsDeep({}, translations)

roTranslations.navigationLinks = navigationLinksTranslations
roTranslations.userDashBoard = userDashBoardTranslations
roTranslations.rules = rulesTranslations
roTranslations.crosswordOfTheDay = crosswordOfTheDayTranslations
roTranslations.archive = archiveTranslations
roTranslations.modals = modalsTranslations
roTranslations.dateLocale = TLocale.RO
