import React from 'react'

type Props = {
  children: React.ReactNode
  userSub?: string
  fallbackComponent: React.ReactElement
}

type TErrorState = { error?: Error }

export class ErrorBoundaryProvider extends React.Component<Props, TErrorState> {
  state: TErrorState = {
    error: undefined,
  }

  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    // TODO
    // const breadCrumb: BreadcrumbData = {
    //   message: 'ErrorBoundary',
    //   data: info,
    //   category: 'React.ErrorInfo',
    //   level: 'critical',
    //   type: 'error',
    // }
    // trackBreadcrumb(breadCrumb)
    // //Sentry tracking
    // trackError(error)
    // //Gtm tracking
    // trackErrorEvent(error, this.props.userSub)
  }

  render() {
    // Note: Errors will still show up on development after a couple of seconds.
    // create-react-app uses react-overlay-error to mirror the console errors on !prod.
    // https://stackoverflow.com/questions/52096804/react-still-showing-errors-after-catching-with-errorboundary
    if (this.state.error) {
      return this.props.fallbackComponent
    }

    return this.props.children
  }
}
