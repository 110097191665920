import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import { TranslationsContext } from 'src/translations/TranslationsContext'
import { useTheme } from 'src/theme/useTheme'

import { Collapsible } from 'src/components/Collapsible'
import { InfoSection } from 'src/components/section/InfoSection'
import { SectionDescription } from 'src/components/section/SectionDescription'
import { Spacer } from 'src/components/Spacer'

import { Markdown } from 'src/components/Markdown'

import { RulesSkeleton } from 'src/components/skeleton/presets/RulesSkeleton'
import { getFetchRulesLoading, getRules } from 'src/api/rules/selectors'
import { fetchRules } from 'src/api/rules/actions'
import { useMedia } from 'src/utils/media/useMedia'
import { getProductFromPath } from 'src/utils/product'

type Props = {
  id?: string
}

export type TRule = {
  title: string
  content: string
}

type TEnrichedRule = TRule & {
  id: string
  opened: boolean
}

export const Rules = ({ id }: Props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { theme } = useTheme()
  const { isMobile } = useMedia()
  const translations = useContext(TranslationsContext)
  const rules = useSelector(getRules)
  const productId = getProductFromPath()
  const isFetchRulesLoading = useSelector(getFetchRulesLoading)
  const [enrichedRules, setEnrichedRules] = useState<TEnrichedRule[]>([])

  // enrich rules with id and opened flag
  useEffect(() => {
    if (rules) {
      const newEnrichedRules: TEnrichedRule[] = rules?.map((rule, index) => {
        const id = `rule-${index + 1}`
        const opened = false
        return { id, opened, ...rule }
      })
      setEnrichedRules(newEnrichedRules)
    }
  }, [rules])

  // open rule section if location hash matches
  useEffect(() => {
    if (location.hash) {
      if (enrichedRules) {
        const ruleIndex = enrichedRules?.findIndex((rules) => rules.id === location.hash.replace('#', ''))
        if (ruleIndex && ruleIndex > -1) {
          const rule = enrichedRules[ruleIndex]
          rule.opened = true
          enrichedRules.splice(ruleIndex, 1, rule)
          setEnrichedRules(enrichedRules)
        }
      }
    }
  }, [location.hash, enrichedRules])

  useEffect(() => {
    if (productId) {
      dispatch(fetchRules(productId))
    }
  }, [dispatch, productId])

  return (
    <StyledInfoSection title={translations.rules.title} id={id}>
      <SectionDescription>{translations.rules.description}</SectionDescription>
      <Spacer $v size={isMobile ? 16 : 24} />
      {isFetchRulesLoading ? (
        <RulesSkeleton />
      ) : (
        <>
          {enrichedRules &&
            enrichedRules.map((rule, key) => (
              <div key={key}>
                <Collapsible
                  id={rule.id}
                  title={rule.title}
                  closedByDefault={false}
                  opened={rule.opened}
                  // onClickTracker={trackSpielregelClick}
                >
                  <Text>
                    <Markdown>{rule.content}</Markdown>
                  </Text>
                </Collapsible>
                <Spacer $v size={theme.components.Rules.itemsIndent} />
              </div>
            ))}
        </>
      )}
    </StyledInfoSection>
  )
}

const StyledInfoSection = styled(InfoSection)`
  margin-bottom: ${({ theme }) => theme.components.Rules.marginBottomMobile};

  ${({ theme }) => theme.media.isDesktop} {
    margin-bottom: ${({ theme }) =>
      theme.components.Rules.marginBottomDesktop}; // === Original margin - section padding - spacer
  }
`

const Text = styled.div`
  margin: 0;
  margin-top: -8px;
  padding: ${({ theme }) => theme.components.Rules.padding};
  color: ${({ theme }) => theme.components.Rules.textColor};

  ${({ theme }) => theme.components.Rules.textStyle}

  ${({ theme }) => theme.media.isMobile} {
    padding: ${({ theme }) => theme.components.Rules.paddingMobile};
  }
`
