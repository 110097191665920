import styled from 'styled-components/macro'

import { config } from 'src/config'
import { pixelify } from 'src/utils/style/pixelify'

import { Image } from 'src/components/Image'
import { NavigationMenu } from './NavigationMenu'
import { UserInfoBlock } from './UserInfoBlock'

import { libertateaMeasures as measures } from 'src/theme/themes/libertatea/measures'
import { ExternalLinkDefault } from 'src/components/links/ExternalLinkDefault'
import { useMedia } from 'src/utils/media/useMedia'
import { ReactComponent as AccountIcon } from 'src/assets/images/libertatea/account_icon.svg'
import libertateaLogo from 'src/assets/images/libertatea/logo.svg'
import { libertateaColors } from 'src/theme/themes/libertatea/colors'
import { MobileMenu } from './MobileNavigationMenu'
import { useHeader } from './useHeader'
import { Spacer } from 'src/components/Spacer'

const LOGO_WIDTH_MOBILE = 110

export const Header = () => {
  const { isDesktop, isMobile } = useMedia()
  const { isLoggedIn, userNickName, onClickUserInfo, onClickUserIcon, isMiniHeaderActive, isHeaderActive } = useHeader()

  if (isMiniHeaderActive || !isHeaderActive) {
    return null
  }

  return (
    <>
      <Wrapper>
        <LineWrapper>
          <NavigationBar>
            <StyledExternalLinkDefault
              href={config.externalLinks.libertatea.url}
              target="_blank"
              aria-label={'Libertatea Logo'}
            >
              <Logo src={libertateaLogo} />
            </StyledExternalLinkDefault>
            {isDesktop && <NavigationMenu />}
            <RightMenu>
              {isLoggedIn ? (
                <UserInfoBlock onClick={onClickUserInfo} userNickName={userNickName} />
              ) : (
                <AccountLoggedOutWrapper onClick={onClickUserIcon}>
                  <StyledUserIcon />
                  <AccountLoggedOutText>Contul meu</AccountLoggedOutText>
                </AccountLoggedOutWrapper>
              )}
            </RightMenu>
            {isMobile && (
              <>
                <MobileMenu />
                <Spacer $h size={8} />
              </>
            )}
          </NavigationBar>
        </LineWrapper>
      </Wrapper>
    </>
  )
}

const StyledExternalLinkDefault = styled(ExternalLinkDefault)`
  display: inline-block;
  height: 50px;
  ${({ theme }) => theme.media.isDesktop} {
    height: 73px;
  }
`

const Logo = styled(Image)``

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  border-bottom: 1px solid ${libertateaColors.grey};

  ${({ theme }) => theme.media.isDesktop} {
    width: 100vw;
    margin-left: calc((${measures.contentWidth} - 100vw) / 2);
  }

  z-index: ${({ theme }) => theme.zIndex.header};

  background-color: ${({ theme }) => theme.colors.shades.white};
`

const LineWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const NavigationBar = styled.div`
  // flex
  display: flex;
  align-items: center;
  justify-content: space-between;

  // spatials
  width: 100%;
  max-width: ${measures.contentWidth};

  // visuals
  background-color: ${({ theme }) => theme.colors.shades.white};

  height: ${measures.navigation.height};

  ${Logo} {
    height: 100%;
  }

  ${({ theme }) => theme.media.isMobile} {
    height: ${measures.navigation.heightMobile};

    ${Logo} {
      height: 50px;
      margin-left: 0.5rem;
    }
  }
`

const RightMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 auto;
  height: 100%;

  svg {
    fill: currentColor;
  }
  ${({ theme }) => theme.media.isMobile} {
    max-width: calc(100% - ${pixelify(LOGO_WIDTH_MOBILE)});
    overflow: hidden;
    justify-self: center;
    justify-content: center;
  }
`

const AccountLoggedOutWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledUserIcon = styled(AccountIcon)`
  height: 24px;
  margin-right: 8px;
  color: ${({ theme }) => theme.colors.shades.black};
  ${({ theme }) => theme.media.isDesktop} {
    margin-right: 17px;
  }
`

const AccountLoggedOutText = styled.div`
  font-size: 24px;
  line-height: 140%;
  ${({ theme }) => theme.media.isMobile} {
    font-size: 18px;
  }
`
