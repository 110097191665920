import { TLoginComponent } from 'src/api/auth/types'
import { ActionTypes, DashboardTab } from './enums'
import {
  TSetMiniHeaderActiveAction,
  TSetDashboardActiveTab,
  TSetUsedLoginComponent,
  TSetHeaderActiveAction,
} from './types'

export const setHeaderActive = (active: boolean): TSetHeaderActiveAction => ({
  type: ActionTypes.SET_HEADER_ACTIVE,
  payload: { active },
})

export const setMiniHeaderActive = (active: boolean): TSetMiniHeaderActiveAction => ({
  type: ActionTypes.SET_MINI_HEADER_ACTIVE,
  payload: { active },
})

export const setDashBoardActiveTab = (tab: DashboardTab): TSetDashboardActiveTab => ({
  type: ActionTypes.SET_DASHBOARD_ACTIVE_TAB,
  payload: tab,
})

export const setUsedLoginComponent = (loginComponent: TLoginComponent): TSetUsedLoginComponent => ({
  type: ActionTypes.SET_USED_LOGIN_COMPONENT,
  payload: loginComponent,
})
