import { ComponentProps } from 'react'
import ReactMarkdown from 'react-markdown'
import { HashLink } from 'react-router-hash-link'
import styled, { css } from 'styled-components/macro'
import { ExternalLink } from 'src/components/links/ExternalLink'
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types'
import { ExternalLinkButton } from 'src/components/links/ExternalLinkButton'
import { InternalLinkButton } from 'src/components/links/InternalLinkButton'

type Props = ComponentProps<typeof ReactMarkdown> & {
  linkAsButton?: boolean
  hideLink?: boolean
}

export const Markdown = ({ linkAsButton, hideLink, ...rest }: Props) => {
  const getLinkComponent = (
    props:
      | (Pick<
          React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
          'key' | keyof React.AnchorHTMLAttributes<HTMLAnchorElement>
        > &
          ReactMarkdownProps)
      | undefined,
  ) => {
    if (props && props.href) {
      const hasHash = props.href?.startsWith('#')

      if (hideLink) {
        return <></>
      }

      if (linkAsButton) {
        return hasHash ? (
          <StyledInternalLinkButton to={props.href} smooth {...props} borderRadius="25px">
            {props.children}
          </StyledInternalLinkButton>
        ) : (
          <StyledExternalLinkButton href={props.href} borderRadius="25px">
            {props.children}
          </StyledExternalLinkButton>
        )
      }

      return hasHash && props.href ? <HashLink to={props.href} smooth {...props} /> : <ExternalLink {...props} />
    }
    return <></>
  }

  return (
    <StyledMarkdown
      components={{
        // Use `ExternalLink` or `HashLink` instead of anchor element `a`
        a: ({ ...props }) => getLinkComponent(props),
      }}
      {...rest}
    />
  )
}

const linkAsButtonStyle = css`
  background: ${({ theme }) => theme.colors.shades.white};
  color: ${({ theme }) => theme.components.common.hightlightColor};
  border-radius: 0px;
  width: 100%;
  max-width: 231px;
`

const StyledInternalLinkButton = styled(InternalLinkButton)`
  ${linkAsButtonStyle}
`
const StyledExternalLinkButton = styled(ExternalLinkButton)`
  ${linkAsButtonStyle}
`

const StyledMarkdown = styled(ReactMarkdown)`
  p {
    margin: 0;
    &:not(:last-child):not(:only-child):not(:has(> strong)) {
      margin-bottom: 24px;
    }
  }

  ${StyledInternalLinkButton},
  ${StyledExternalLinkButton} {
    text-shadow: none;
    margin-top: 32px;

    ${({ theme }) => theme.media.isDesktop} {
      margin-top: 24px;
    }
  }
`
