import { libertateaColors } from 'src/theme/themes/libertatea/colors'

export type TCrosswordStyle = typeof defaultCrossword

export const defaultCrossword = {
  allowNonSquare: false,
  columnBreakpoint: '768px',
  gridBackground: 'rgb(0,0,0)',
  cellBackground: 'rgb(237,237,239)',
  cellBorder: 'rgb(0,0,0)',
  textColor: 'rgb(51,51,51)',
  numberColor: 'rgb(51,51,51)',
  focusBackground: 'rgb(98, 160, 26)',
  highlightBackground: 'rgb(169, 226, 154)',
  actionsBorderColor: libertateaColors.grey,
}
