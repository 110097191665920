import { ThunkAction } from 'redux-thunk'
import { TReduxState } from 'src/redux/store'
import { publicClient } from 'src/utils/clients'

import { config } from 'src/config'
import { buildUrl } from 'src/api/utils/buildUrl'
import { ActionTypes } from './enums'
import { Actions, TRules } from './types'

export const fetchRules =
  (productId: string): ThunkAction<void, TReduxState, unknown, Actions> =>
  async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.FETCH_RULES,
      })

      const url = buildUrl(`${config.api.backend.url}/rules/${productId}`)
      const res = await publicClient.get<TRules>(url)

      if (res.status !== 200) {
        throw new Error('Failed to fetch rules')
      }

      dispatch({
        type: ActionTypes.FETCH_RULES_SUCCESS,
        payload: res.data,
      })
    } catch (err) {
      dispatch({
        type: ActionTypes.FETCH_RULES_FAILURE,
        payload: { error: err },
      })
    }
  }
