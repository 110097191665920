export enum ActionTypes {
  FETCH_LATEST_CROSSWORD = '@CROSSWORDS/FETCH_LATEST_CROSSWORD',
  FETCH_LATEST_CROSSWORD_SUCCESS = '@CROSSWORDS/FETCH_LATEST_CROSSWORD_SUCCESS',
  FETCH_LATEST_CROSSWORD_FAILURE = '@CROSSWORDS/FETCH_LATEST_CROSSWORD_FAILURE',

  FETCH_CROSSWORDS = '@CROSSWORDS/FETCH_CROSSWORDS',
  FETCH_CROSSWORDS_SUCCESS = '@CROSSWORDS/FETCH_CROSSWORDS_SUCCESS',
  FETCH_CROSSWORDS_FAILURE = '@CROSSWORDS/FETCH_CROSSWORDS_FAILURE',
  FETCH_CROSSWORDS_ARCHIVE_SUCCESS = '@CROSSWORDS/FETCH_CROSSWORDS_ARCHIVE_SUCCESS',
  SET_CROSSWORDS_ARCHIVE_FILTER = '@CROSSWORDS/SET_CROSSWORDS_ARCHIVE_FILTER',

  FETCH_CROSSWORD_STATE = '@CROSSWORDS/FETCH_CROSSWORD_STATE',
  FETCH_CROSSWORD_STATE_SUCCESS = '@CROSSWORDS/FETCH_CROSSWORD_STATE_SUCCESS',
  FETCH_CROSSWORD_STATE_FAILURE = '@CROSSWORDS/FETCH_CROSSWORD_STATE_FAILURE',
  CLEAN_FETCH_CROSSWORD_STATE_STATES = '@CROSSWORDS/CLEAN_FETCH_CROSSWORD_STATE_STATES',

  UPDATE_CROSSWORD_STATE = '@CROSSWORDS/UPDATE_CROSSWORD_STATE',
  UPDATE_CROSSWORD_STATE_SUCCESS = '@CROSSWORDS/UPDATE_CROSSWORD_STATE_SUCCESS',
  UPDATE_CROSSWORD_STATE_FAILURE = '@CROSSWORDS/UPDATE_CROSSWORD_STATE_FAILURE',
  CLEAN_UPDATE_CROSSWORD_STATE_STATES = '@CROSSWORDS/CLEAN_UPDATE_CROSSWORD_STATE_STATES',

  CHECK_WORD = '@CROSSWORD/CHECK_WORD',
  CHECK_WORD_SUCCESS = '@CROSSWORD/CHECK_WORD_SUCCESS',
  CHECK_WORD_FAILURE = '@CROSSWORD/CHECK_WORD_FAILURE',
  CLEAN_CHECK_WORD_STATES = '@CROSSWORD/CLEAN_CHECK_WORD_STATES',

  CHECK_CROSSWORD = '@CROSSWORD/CHECK_CROSSWORD',
  CHECK_CROSSWORD_SUCCESS = '@CROSSWORD/CHECK_CROSSWORD_SUCCESS',
  CHECK_CROSSWORD_FAILURE = '@CROSSWORD/CHECK_CROSSWORD_FAILURE',
  CLEAN_CHECK_CROSSWORD_STATES = '@CROSSWORD/CLEAN_CHECK_CROSSWORD_STATES',

  CLEAR_CORRECT_INCORRECT_WORDS = '@CROSSWORD/CLEAR_CORRECT_INCORRECT_WORDS',
  CLEAR_INCORRECT_WORDS = '@CROSSWORD/CLEAR_INCORRECT_WORDS',
  CLEAR_FROM_INCORRECT_WORDS = '@CROSSWORD/CLEAR_FROM_INCORRECT_WORDS',
}
