import { ReactNode } from 'react'
import styled, { keyframes } from 'styled-components/macro'

import { theme } from './theme'

type Props = {
  children: ReactNode
  className?: string
  minHeight?: number
}

export const Skeleton = ({ children, className, minHeight = 300 }: Props) => (
  <SkeletonContainer className={className} minHeight={minHeight}>
    <AnimatedBackground>{children}</AnimatedBackground>
  </SkeletonContainer>
)

const placeHolderShimmerKeyframe = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`

const SkeletonContainer = styled.div<{ minHeight: number }>`
  min-height: ${({ minHeight }) => minHeight}px;
  width: 100%;
  padding: ${theme.DEFAULT_PADDING};
  box-sizing: border-box;

  background-color: ${theme.BACKGROUND_COLOR};
  border: 1px solid ${theme.BORDER_COLOR};
  border-radius: 2px;
`

const AnimatedBackground = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;
  height: 100%;
  width: 100%;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: ${placeHolderShimmerKeyframe};
  -webkit-animation-timing-function: linear;

  background: #f6f7f8;
  background-image: -webkit-linear-gradient(left, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-image: linear-gradient(left, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
`
