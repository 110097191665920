import styled, { css } from 'styled-components/macro'

import { AnchorHTMLAttributes, MouseEventHandler } from 'react'

import { buttonTheme, buttonStyles, TButtonTheme } from 'src/components/buttons/Button'

type Props = {
  href: string
  componentTheme?: TButtonTheme
  fullWidth?: boolean
  disabled?: boolean
  borderRadius?: string
} & AnchorHTMLAttributes<HTMLAnchorElement>

export const ExternalLinkButton = ({ onClick, ...props }: Props) => {
  // const userSub = useSelector(getUserSub)

  const onLinkClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    // const buttonLabel = getChildrenText(props.children)

    onClick && onClick(e)

    // trackExternalLinkClick(buttonLabel, props.href, userSub) TODO:GA
  }

  return <ExternalLinkButtonWrap {...props} onClick={onLinkClick} />
}

const ExternalLinkButtonWrap = styled.a<Props>`
  text-decoration: none;
  &:hover {
    color: #fff;
  }
  ${buttonStyles}
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}
  ${({ componentTheme }) => buttonTheme[componentTheme || 'primary']}
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}`}
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`
