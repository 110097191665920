import { getTenant } from 'src/utils/tenant'
import { Tenants } from 'src/enums/tenants'

import { theme as defaultTheme } from './themes/default'

import { libertatea } from './themes/libertatea'

export const getTheme = (productId?: string) => {
  const tenant = getTenant()

  switch (tenant) {
    case Tenants.LIBERTATEA:
      return libertatea
  }

  return defaultTheme
}
