import { colors } from 'src/theme/colors'
import { fonts } from 'src/theme/fonts'

export type TCollapsibleStyle = typeof defaultCollapsible

export const defaultCollapsible = {
  background: colors.blick.grey15,
  backgroundHover: colors.blick.grey15,
  contentBackground: colors.blick.grey15,
  borderRadius: '8px',
  padding: '16px 24px 16px 16px',
  headerTextStyle: `
        font-family: ${fonts.sourceSansPro};
        font-size: 16px;
        line-height: 22px;
        font-weight: 700;
    `,
  boxShadow: 'none',
  arrowColor: colors.blick.grey90,
  arrowWidth: '16px',
  arrowHeight: '12px',
  collapsedColor: '',
}
