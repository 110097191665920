import styled from 'styled-components/macro'

import { ReactComponent as WhatsappIcon } from 'src/assets/images/share/Whatsapp.svg'
import { ReactComponent as WhatsappIconMobile } from 'src/assets/images/share/Whatsapp_mobile.svg'
import { useMedia } from 'src/utils/media/useMedia'
import { TCommonSocialMediaButtonProps } from './types'
import { objectToGetParams } from './utils'
import { shareButtonStyles } from './styles'

function whatsappLink(url: string, isMobileDevice: boolean, title?: string) {
  return (
    'https://' +
    (isMobileDevice ? 'api' : 'web') +
    '.whatsapp.com/send' +
    objectToGetParams({
      text: title ? `${title} ${url}` : url,
    })
  )
}

export const WhatsappShareButton = ({
  urlToShare,
  title,
  target = '_self',
  disabled,
  ...restProps
}: TCommonSocialMediaButtonProps) => {
  const { isMobileDevice, isMobile } = useMedia()
  const url = urlToShare ? urlToShare : window.location.href
  const link = whatsappLink(url, isMobileDevice, title)

  return (
    <StyledShareButton href={link} target={target} $disabled={disabled} {...restProps}>
      {isMobile ? <WhatsappIconMobile /> : <WhatsappIcon />}
    </StyledShareButton>
  )
}

const StyledShareButton = styled.a<{ $disabled?: boolean }>`
  ${shareButtonStyles}
  ${({ $disabled }) => {
    if ($disabled) {
      return `
        opacity:.5;
        pointer-events:none;
      `
    }
  }}
`
