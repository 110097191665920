import { useContext, useEffect } from 'react'
import { InfoSection } from 'src/components/section/InfoSection'
import { SectionDescription } from 'src/components/section/SectionDescription'
import { TranslationsContext } from 'src/translations/TranslationsContext'
import { styled } from 'styled-components/macro'
import { CrosswordCard } from 'src/components/CrosswordCard/CrosswordCard'
import { getProductFromPath } from 'src/utils/product'
import { useDispatch, useSelector } from 'react-redux'
import { TFetchLatestCrossword } from 'src/api/crosswords/actions'
import { getLatestCrossword } from 'src/api/crosswords/selectors'
import { generatePath, useNavigate } from 'react-router-dom'
import { getIsLoggedIn } from 'src/api/auth/selectors'
import { login } from 'src/api/auth/actions'
import { DateFormat, TDateISODate, formatDate } from 'src/utils/date/formatDate'
import { Path } from 'src/router/enums'

type Props = {
  id?: string
}

export const CrosswordOfTheDay = ({ id }: Props) => {
  const navigate = useNavigate()
  const translations = useContext(TranslationsContext)
  const dispatch = useDispatch()
  const productId = getProductFromPath()

  const isLoggedIn = useSelector(getIsLoggedIn)
  const crossword = useSelector(getLatestCrossword)

  const generatedCrosswordPath = (day: TDateISODate) =>
    generatePath(Path.CrossowordForDay, { productName: productId || '', date: day })

  useEffect(() => {
    if (productId) {
      dispatch(TFetchLatestCrossword(productId))
    }
  }, [dispatch, productId])

  return (
    <StyledInfoSection title={translations.crosswordOfTheDay.title} id={id}>
      <SectionDescription>{translations.crosswordOfTheDay.description}</SectionDescription>
      <Row $singleColumn={!crossword}>
        {crossword && (
          <Column>
            <CrosswordCard
              ribbon={translations.crosswordOfTheDay.ribbon}
              title={translations.crosswordOfTheDay.today}
              subtitle={`${translations.crosswordOfTheDay.published} ${formatDate(
                crossword?.day,
                DateFormat.DAY_MONTH_YEAR,
              )}`}
              buttonLabel={
                !isLoggedIn ? translations.crosswordOfTheDay.loginButton : translations.crosswordOfTheDay.playButton
              }
              buttonOnClick={
                !isLoggedIn
                  ? () =>
                      dispatch(
                        login('method', {
                          returnUrl: window.location.origin + generatedCrosswordPath(crossword.day),
                        }),
                      )
                  : () => navigate(generatedCrosswordPath(crossword.day))
              }
            />
          </Column>
        )}
        <Column>
          <CrosswordCard
            title={translations.crosswordOfTheDay.previous}
            subtitle={translations.crosswordOfTheDay.previousDescription}
            buttonLabel={translations.crosswordOfTheDay.archiveButton}
            buttonTheme="secondary"
            buttonOnClick={() => navigate(`/${productId}/archive`)}
          />
        </Column>
      </Row>
    </StyledInfoSection>
  )
}

const StyledInfoSection = styled(InfoSection)`
  padding-top: 40px;
`

const Row = styled.div<{ $singleColumn: boolean }>`
  display: flex;
  justify-content: ${({ $singleColumn }) => ($singleColumn ? 'center' : 'space-between')};

  ${({ theme }) => theme.media.isMobile} {
    flex-direction: column;
  }
`

const Column = styled.div`
  width: calc(50% - 12px);

  ${({ theme }) => theme.media.isMobile} {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
`
