import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { CrosswordCard } from 'src/components/CrosswordCard/CrosswordCard'
import { Select } from 'src/components/Select'
import { TSelectOption } from 'src/components/Select/types'
import { InfoSection } from 'src/components/section/InfoSection'
import { SectionDescription } from 'src/components/section/SectionDescription'
import { TranslationsContext } from 'src/translations/TranslationsContext'
import styled from 'styled-components/macro'
import crosswordsIcon from 'src/assets/images/crosswords_icon.svg'
import { Button } from 'src/components/buttons/Button'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCrosswords, setArchiveFilter } from 'src/api/crosswords/actions'
import { getProductFromPath } from 'src/utils/auth'
import { DateFormat, TDateISODate, formatDate } from 'src/utils/date/formatDate'
import { getCrosswordsArchive, getCrosswordsArchiveFilter } from 'src/api/crosswords/selectors'
import { generatePath, useNavigate } from 'react-router-dom'
import { Path } from 'src/router/enums'
import dayjs from 'dayjs'
import { getIsLoggedIn } from 'src/api/auth/selectors'
import { login } from 'src/api/auth/actions'

const NUMBER_OF_CROSSWORDS_TO_SHOW = 32

export const Archive = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const productId = getProductFromPath()
  const translations = useContext(TranslationsContext)

  const isLoggedIn = useSelector(getIsLoggedIn)
  const crosswords = useSelector(getCrosswordsArchive)
  const storeFilter = useSelector(getCrosswordsArchiveFilter)

  const [selectOptions, setSelectOptions] = useState<TSelectOption[]>()
  const [selectValue, setSelectValue] = useState<TSelectOption>()
  const [numberToShow, setNumberToShow] = useState(NUMBER_OF_CROSSWORDS_TO_SHOW)
  const [filteredCrosswords, setFilteredCrosswords] = useState(crosswords)

  const crosswordsToShow = useMemo(() => {
    return filteredCrosswords?.slice(0, numberToShow)
  }, [filteredCrosswords, numberToShow])

  const shouldShowButton = filteredCrosswords && crosswordsToShow && filteredCrosswords.length > crosswordsToShow.length

  const generatedCrosswordPath = (day: TDateISODate) =>
    generatePath(Path.CrossowordForDay, { productName: productId || '', date: day })

  useEffect(() => {
    if (!crosswords) {
      dispatch(fetchCrosswords({ productId, isAcrhive: true }))
    } else if (!filteredCrosswords) {
      setFilteredCrosswords(crosswords)
    }
  }, [crosswords, dispatch, filteredCrosswords, productId, selectValue])

  const onFilterChange = useCallback(
    (value: TSelectOption) => {
      dispatch(setArchiveFilter(value))
      setSelectValue(value)
      setNumberToShow(NUMBER_OF_CROSSWORDS_TO_SHOW)

      const crosswordsByMonth = crosswords?.filter(
        (crossword) => dayjs(crossword.day).month() === dayjs(value.value).month(),
      )
      crosswordsByMonth && setFilteredCrosswords(crosswordsByMonth)
    },
    [crosswords, dispatch],
  )

  //set selectOptions based on fetching all products, is done once.
  //temporary solution before we get months from another source
  useEffect(() => {
    if (!selectOptions && crosswords) {
      const options: TSelectOption[] = crosswords.map((crossword) => {
        return {
          label: formatDate(new Date(crossword.day), DateFormat.YEAR_MONTH_ALT),
          value: formatDate(new Date(crossword.day), DateFormat.YEAR_MONTH),
        }
      })
      const uniqueOptions = [...new Set(options.map((o) => JSON.stringify(o)))].map((s) => JSON.parse(s))
      setSelectOptions(uniqueOptions)
      onFilterChange(storeFilter || uniqueOptions[0])
    }
  }, [crosswords, onFilterChange, selectOptions, storeFilter])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Wrapper>
      <InfoSection title={translations.archive.title} iconSrc={crosswordsIcon}>
        <SectionDescription>{translations.archive.description}</SectionDescription>
        {!!selectOptions?.length && (
          <StyledSelect options={selectOptions} value={selectValue} placeholder={'Period'} onChange={onFilterChange} />
        )}
        <Grid>
          {crosswordsToShow?.map((crossword) => {
            return (
              <GridItem key={crossword.id}>
                <CrosswordCard
                  title={formatDate(crossword.day, DateFormat.DAY_NAME)}
                  subtitle={`${translations.archive.published} ${formatDate(crossword.day, DateFormat.DAY_MONTH_YEAR)}`}
                  completion={crossword.completion}
                  buttonLabel={translations.archive.playNow}
                  buttonOnClick={
                    !isLoggedIn
                      ? () =>
                          dispatch(
                            login('method', {
                              returnUrl: window.location.origin + generatedCrosswordPath(crossword.day),
                            }),
                          )
                      : () => navigate(generatedCrosswordPath(crossword.day))
                  }
                />
              </GridItem>
            )
          })}
        </Grid>
        {shouldShowButton && (
          <Button
            componentTheme="secondary"
            onClick={() => setNumberToShow((prev) => prev + NUMBER_OF_CROSSWORDS_TO_SHOW)}
          >
            {translations.archive.showMore}
          </Button>
        )}
      </InfoSection>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const GridItem = styled.div`
  width: calc(33.3333% - 16px);
  margin-right: 24px;
  margin-bottom: 24px;

  ${({ theme }) => theme.media.isMobile} {
    width: 100%;
    margin-right: 0;
  }

  &:nth-child(3n + 3) {
    margin-right: 0;
  }
`

const StyledSelect = styled(Select)`
  width: 315px;
  margin: auto;
  margin-bottom: 24px;
`
