import styled from 'styled-components/macro'
import { libertateaColors } from 'src/theme/themes/libertatea/colors'
import { getIsHeaderActive } from 'src/state/app/selectors'
import { useSelector } from 'react-redux'

export const Footer = () => {
  const isHeaderActive = useSelector(getIsHeaderActive)
  if (!isHeaderActive) {
    return null
  }

  return <FooterWrap>© Ringier Romania. Toate drepturile rezervate</FooterWrap>
}

const FooterWrap = styled.div`
  background-color: ${libertateaColors.black};
  padding: 24px 16px;
  color: #fff;
  font-weight: 300;
  line-height: 140%;
  text-align: center;
  margin-top: 172px;

  ${({ theme }) => theme.media.isDesktop} {
    margin-top: 194px;
  }
`
