import { ErrorBoundaryProvider } from 'src/components/error/ErrorBoundaryProvider'
import { DrawerProvider } from 'src/drawer'
import { Error500 } from 'src/components/error/Error500'
import { getTenant } from 'src/utils/tenant'
import { BlickLayout } from 'src/layout/BlickLayout'
import { LibertateaLayout } from 'src/layout/LibertateaLayout'
import { Tenants } from 'src/enums/tenants'
import { useEffect } from 'react'
import { checkBackendAuth } from 'src/api/auth/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getIsLoggedIn } from 'src/api/auth/selectors'
import { fetchUserStats } from 'src/api/user/actions'
import { getProductFromPath } from 'src/utils/product'

export const AppLayout = () => {
  const dispatch = useDispatch()

  // const userSub = useSelector(getUserSub)
  const userSub = ''
  const tenant = getTenant()
  const isLoggedIn = useSelector(getIsLoggedIn)
  const producdId = getProductFromPath()
  // const { loginMethod } = useUrlParams()

  useEffect(() => {
    // Initially
    if (tenant !== Tenants.BLICK) {
      dispatch(checkBackendAuth(''))
    } else {
      // dispatch(checkBlickAuth(loginMethod))
    }
  }, [dispatch, tenant])

  // useEffect(() => trackAppInitialization(), [])

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchUserStats(producdId))
    }
  }, [isLoggedIn, dispatch, producdId])

  const getLayout = () => {
    switch (tenant) {
      case Tenants.BLICK:
        return <BlickLayout />
      default:
        return <LibertateaLayout />
    }
  }

  return (
    <ErrorBoundaryProvider userSub={userSub} fallbackComponent={<Error500 />}>
      <DrawerProvider>{getLayout()}</DrawerProvider>
    </ErrorBoundaryProvider>
  )
}
