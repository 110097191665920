import styled from 'styled-components/macro'
import { ToastContainer as OriginalToastContainer, Slide } from 'react-toastify'

import { CloseIcon } from 'src/components/CloseIcon'

export const ToastContainer = () => (
  <OriginalToastContainer
    position="top-center"
    closeButton={() => <StyledCloseIcon size={16} color="white" />}
    autoClose={5000}
    hideProgressBar={true}
    transition={Slide}
  />
)

const StyledCloseIcon = styled(CloseIcon)`
  top: 4px;
`
