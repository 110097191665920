import { libertateaFonts } from 'src/theme/themes/libertatea/fonts'
import styled from 'styled-components/macro'
import { Button, TButtonTheme } from 'src/components/buttons/Button'
import { Image } from 'src/components/Image'
import crosswordBackground from 'src/assets/images/crosswords_background.jpg'
import { libertateaColors } from 'src/theme/themes/libertatea/colors'
import { Spacer } from 'src/components/Spacer'
import { ReactComponent as CheckCicrleIcon } from 'src/assets/images/check-circlev2_icon.svg'
import { ReactComponent as InProgressIcon } from 'src/assets/images/in_progress_icon.svg'
import { getRounded } from 'src/utils/other'

type Props = {
  ribbon?: string
  title: string
  subtitle: string
  buttonLabel?: string
  buttonOnClick?: () => void
  buttonTheme?: TButtonTheme
  completion?: number
}

export const CrosswordCard = ({
  ribbon,
  title,
  subtitle,
  buttonLabel = 'Play Now',
  buttonOnClick,
  buttonTheme = 'primary',
  completion,
}: Props) => {
  const isCompleted = completion === 1

  return (
    <Wrapper>
      {ribbon ? (
        <Ribbon>
          <span>{ribbon}</span>
        </Ribbon>
      ) : (
        <></>
      )}
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
      <ImageHolder>
        <StyledImage src={crosswordBackground} />
        <StyledButton componentTheme={buttonTheme} onClick={() => buttonOnClick && buttonOnClick()}>
          {buttonLabel}
        </StyledButton>
        {!!completion && (
          <Completion $completed={isCompleted}>
            {isCompleted ? <CheckCicrleIcon /> : <InProgressIcon />}
            <Spacer $h size={4} />
            {isCompleted ? 'Completed' : `${getRounded(completion * 100)}% completed`}
          </Completion>
        )}
      </ImageHolder>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: ${libertateaColors.greyVeryLightAlt};
  padding: 24px;
  padding-bottom: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
`

const Ribbon = styled.div`
  width: 0px;
  height: 0px;
  border-bottom: 100px solid transparent;
  border-left: 100px solid ${libertateaColors.red};
  position: absolute;
  top: 0;
  left: 0;

  span {
    position: absolute;
    left: -106px;
    top: 25px;
    transform: rotate(-45deg);
    text-align: center;
    width: 73px;
    color: ${({ theme }) => theme.colors.shades.white};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`

const Title = styled.div`
  font-family: ${libertateaFonts.signika};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-align: center;
`

const SubTitle = styled.div`
  font-family: ${libertateaFonts.signika};
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  text-align: center;
`

const ImageHolder = styled.div`
  width: 280px;
  height: 155px;
  display: flex;
  margin-top: 16px;
  position: relative;
  flex-direction: column;
  align-items: center;
`

const StyledImage = styled(Image)`
  width: 100%;
  position: absolute;
`

const StyledButton = styled(Button)`
  width: 160px;
  position: relative;
  margin-top: 52px;
`

const Completion = styled.div<{ $completed: boolean }>`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  background: ${({ theme, $completed }) =>
    $completed ? theme.colors.feedbackAlt.positive : theme.colors.shades.shade12};
  color: ${({ theme }) => theme.colors.shades.white};
  padding: 0 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 20px;
  position: relative;
  margin-top: 16px;
`
