import { ComponentProps, useContext } from 'react'
import { Social } from 'src/components/Social/Social'
import { Button } from 'src/components/buttons/Button'
import { Modal } from 'src/components/modal'
import { TranslationsContext } from 'src/translations/TranslationsContext'
import styled from 'styled-components/macro'

type Props = ComponentProps<typeof Modal> & {
  shareTitle: string
  shareSubject: string
}

export const SolvedModal = ({ isOpen, onClose, shareTitle, shareSubject }: Props) => {
  const translations = useContext(TranslationsContext)
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeButtonColor={'white'}
      style={{
        content: {
          padding: '24px',
          background: 'linear-gradient(135deg, #6C0147 0.09%, #BA1456 51.56%, #E4240F 100%)',
        },
      }}
    >
      <Wrapper>
        <Title>
          {translations.modals.solved.title}
          <br />
          {translations.modals.solved.subtitle}
        </Title>
        <Subtitle>{translations.modals.solved.share}</Subtitle>
        <Social shareSubject={shareSubject} shareTitle={shareTitle} urlToShare={window.location.href} />
        <StyledButton componentTheme="secondary" onClick={onClose}>
          {translations.modals.solved.close}
        </StyledButton>
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div`
  text-align: center;
`

const Title = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.shades.white};
  margin-top: 24px;
  margin-bottom: 40px;
`

const Subtitle = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.shades.white};
  margin-bottom: 8px;
`

const StyledButton = styled(Button)`
  background: transparent;
  border-color: ${({ theme }) => theme.colors.shades.white};
  color: ${({ theme }) => theme.colors.shades.white};
  margin-top: 24px;

  &:hover {
    border-color: ${({ theme }) => theme.colors.shades.white};
    color: ${({ theme }) => theme.colors.shades.white};
  }
`
