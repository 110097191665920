import { colors } from 'src/theme/colors'

export type TToastStyle = typeof defaultToast

export const defaultToast = {
  defaultColor: colors.blick.grey100,
  defaultBackgroundColor: colors.shades.greyVeryLight,
  defaultBorderColor: colors.blick.grey70,
  defaultBorderRadius: '8px',
  successColor: colors.blick.grey100,
  successBackgroundColor: colors.feedbackAlt.positive,
  successBorderColor: colors.feedbackAlt.positive,
  warningBackgroundColor: colors.feedbackAlt.warningBg,
  warningBorderColor: colors.food.food60,
  errorBackgroundColor: colors.blick.brand5,
  errorBorderColor: colors.blick.brand,
  infoBackgroundColor: colors.secondary.focus,
  closeIcon: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
}
