import { DEFAULT_ERRORS } from 'src/utils/errors'

import { ErrorBoundaryProvider } from 'src/components/error/ErrorBoundaryProvider'
import { Error } from 'src/components/error/Error'
import { Hero } from 'src/containers/Hero'
import { Rules } from 'src/containers/Rules'
import { CrosswordOfTheDay } from 'src/containers/CrosswordOfTheDay'
import { navHashes } from 'src/containers/libertatea/Header/useNavigation'
import { SocialLayoutVersion } from 'src/components/Social/SocialLayoutVersion'

type Props = {
  overwriteProductName?: string
}

export const Product = ({ overwriteProductName }: Props) => {
  return (
    <ErrorBoundaryProvider fallbackComponent={<Error errorText={DEFAULT_ERRORS.COMPONENT_IS_BROKEN} />}>
      <Hero id={navHashes.start} />
      <CrosswordOfTheDay id={navHashes.crosswords} />
      <SocialLayoutVersion />
      <Rules id={navHashes.faq} />
    </ErrorBoundaryProvider>
  )
}
