import { memo } from 'react'
import ReactSelect, { Props as SelectProps, ActionMeta } from 'react-select'
import styled from 'styled-components/macro'

import { SelectControl } from './SelectControl'
import { SelectDropdownIndicator } from './SelectDropdownIndicator'
import { SelectDropdownLoadingIndicator } from './SelectDropdownLoadingIndicator'
import { TSelectOption } from './types'
import { useMedia } from 'src/utils/media/useMedia'

type Props = {
  //Library built in props
  options: TSelectOption[]
  defaultValue?: TSelectOption | null
  value?: TSelectOption | null
  placeholder?: string
  className?: string
  classNamePrefix?: string
  isDisabled?: boolean
  isLoading?: boolean
  isClearable?: boolean
  onChange?: (value: TSelectOption, action: ActionMeta<TSelectOption>) => void
  onMenuOpen?: () => void
  onMenuClose?: () => void
  //Custom props
  isSucceed?: boolean
  isFailed?: boolean
  isPending?: boolean
  isNoBetState?: boolean
  icon?: string
}

export const Select = memo(function Select(props: Props) {
  const isSelectDisabled = props.isDisabled || props.isLoading

  const { isDesktop } = useMedia()

  const getIndicator = () => {
    if (isSelectDisabled) {
      return null
    }
    return SelectDropdownIndicator
  }

  return (
    <ReactSelectStyled
      options={props.options}
      defaultValue={props.defaultValue}
      value={props.value}
      placeholder={props.placeholder || 'Auswählen'}
      className={props.className || DEFAULT_CLASSNAME}
      classNamePrefix={props.classNamePrefix || DEFAULT_CLASSNAME_PREFIX}
      isDisabled={isSelectDisabled}
      isLoading={props.isLoading}
      isClearable={props.isClearable}
      onChange={(value: unknown, action: ActionMeta<unknown>) =>
        props.onChange && props?.onChange(value as TSelectOption, action as ActionMeta<TSelectOption>)
      }
      onMenuOpen={props.onMenuOpen}
      onMenuClose={props.onMenuClose}
      components={{
        DropdownIndicator: getIndicator(),
        IndicatorSeparator: null,
        SelectDropdownLoadingIndicator,
        //@ts-expect-error
        Control: SelectControl,
      }}
      isSearchable={isDesktop}
      //Custom props
      isSucceed={props.isSucceed}
      isFailed={props.isFailed}
      isPending={props.isPending}
      isNoBetState={props.isNoBetState}
      icon={props.icon}
    />
  )
})

const DEFAULT_CLASSNAME_PREFIX = 'react-select'
const DEFAULT_CLASSNAME = 'react-select-container'

const ReactSelectStyled = styled(ReactSelect)<Props & SelectProps>`
  .${DEFAULT_CLASSNAME} {
    color: ${({ theme }) => theme.components.common.color};
  }
  .${DEFAULT_CLASSNAME_PREFIX}__control {
    min-height: 56px;
    border-radius: 0px;
    border-color: ${({ isSucceed, isFailed, theme }) => {
      if (isSucceed) {
        return `${theme.colors.blick.sport55}`
      } else if (isFailed) {
        return `${theme.colors.feedback.negative}`
      }
      return `${theme.colors.blick.brand100}`
    }};
    background-color: ${({ isPending, theme }) => {
      if (isPending) {
        return `${theme.colors.blick.grey10}`
      }

      return `${theme.colors.shades.white}`
    }};
  }
  .${DEFAULT_CLASSNAME_PREFIX}__placeholder {
    width: fit-content;
    margin: 0;
    color: ${({ isNoBetState, theme }) => {
      if (isNoBetState) {
        return `${theme.colors.blick.grey100}`
      }
      return `${theme.colors.blick.grey75}`
    }};
  }
  .${DEFAULT_CLASSNAME_PREFIX}__single-value {
    color: ${({ isPending, theme }) => (isPending ? theme.colors.blick.grey75 : theme.components.common.color)};
    margin-left: 0;
    margin-right: 0;
    font-weight: 700;
  }
  ${DEFAULT_CLASSNAME_PREFIX}__input-container {
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  .${DEFAULT_CLASSNAME_PREFIX}__indicators {
    padding-right: 16px;
  }
  .${DEFAULT_CLASSNAME_PREFIX}__indicator {
    padding: 0;
    color: inherit;
  }
  .${DEFAULT_CLASSNAME_PREFIX}__control--menu-is-open .${DEFAULT_CLASSNAME_PREFIX}__dropdown-indicator > div {
    transform: rotate(180deg);
  }
  .${DEFAULT_CLASSNAME_PREFIX}__control:hover {
    border-color: ${({ theme }) => theme.colors.blick.brand100};
  }
  .${DEFAULT_CLASSNAME_PREFIX}__control--is-focused, .${DEFAULT_CLASSNAME_PREFIX}__control--is-focused:hover {
    border-color: #2460c5;
    box-shadow: 0 0 0 1px #2460c5;
  }

  .${DEFAULT_CLASSNAME_PREFIX}__value-container {
    overflow: initial;
  }
  .${DEFAULT_CLASSNAME_PREFIX}__menu {
    box-shadow:
      0px 1px 2px rgba(0, 0, 0, 0.3),
      0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    margin-top: 2px;
    margin-bottom: 0;
    z-index: 2;
  }
  .${DEFAULT_CLASSNAME_PREFIX}__option:active {
    background-color: ${({ theme }) => theme.colors.blick.grey30};
  }
  .${DEFAULT_CLASSNAME_PREFIX}__option--is-focused {
    background-color: ${({ theme }) => theme.colors.blick.grey30};
  }
  .${DEFAULT_CLASSNAME_PREFIX}__option--is-selected {
    background-color: ${({ theme }) => theme.colors.blick.grey55};
    color: #fff;
  }
  .${DEFAULT_CLASSNAME_PREFIX}__option--is-selected:active {
    background-color: ${({ theme }) => theme.colors.blick.grey55};
  }
`
