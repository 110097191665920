import { TUserDashBoardTranslations } from 'src/translations/de/default/userDashBoard'

export const userDashBoardTranslations: TUserDashBoardTranslations = {
  currentMonth: 'Current month',
  moreGames: 'Discover more games',
  myAccount: 'My account settings',
  logout: 'Logout',
  completedCorrectly: '',
  pointsEarned: '',
  totalPoints: '',
}
