import { sanitizeUrl } from 'src/utils/other'
import { getTenant, getTenantConfig } from 'src/utils/tenant'

const urlify = (envVar: string | undefined, fallback?: string) => sanitizeUrl(envVar, fallback)

const version = process.env.REACT_APP_VERSION || 'No commit hash found'
const env = process.env.REACT_APP_ENV || process.env.NODE_ENV
const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
const isDev = env !== 'prod' && env !== 'production'
const isProd = env === 'prod' || env === 'production'

if (typeof window !== 'undefined') {
  // @ts-expect-error
  window.VERSION = version
}

const currentTenant = getTenant()
const tenantConfig = getTenantConfig(currentTenant)

export const config = {
  version,
  release: process.env.REACT_APP_SENTRY_RELEASE_NAME || 'localhost',
  isLocalhost,
  isProd,
  isDev,
  selfUrl: tenantConfig?.frontend_url,
  app: {
    rootElementId: 'root',
    env,
  },
  // Service APIs
  api: {
    backend: {
      url: urlify(tenantConfig?.backend_url, 'https://api.d.rebus.libertatea.ro/api/v1'),
    },
    images: {
      url: urlify(process.env.REACT_APP_IMAGE_URL, 'https://img.blick.ch'),
    },
  },
  tracking: {
    isEnabled: !isLocalhost,
  },
  externalLinks: {
    blickUrl: 'https://www.blick.ch',
    libertatea: {
      url: 'https://www.libertatea.ro',
      gamecenter: 'https://www.libertatea.ro/concurs',
      myAccount: 'https://www.libertatea.ro/cont/preferinte',
    },
  },
}
