import { ReactNode } from 'react'
import styled from 'styled-components/macro'
import { SectionTitle, SectionTitleWrapper } from './SectionTitle'
import { SectionContent } from './SectionContent'
import { Image } from 'src/components/Image'

type Props = {
  title?: string
  badge?: string
  id?: string
  iconSrc?: string
  children?: ReactNode
  className?: string
}

export const InfoSection = ({ title, badge, id, children, className, iconSrc }: Props) => {
  return (
    <Wrapper id={id} className={className}>
      {iconSrc && (
        <IconWrap>
          <Image src={iconSrc} />
        </IconWrap>
      )}
      {badge && <Badge>{badge}</Badge>}
      {title && (
        <SectionTitleWrapper>
          <SectionTitle>{title}</SectionTitle>
        </SectionTitleWrapper>
      )}
      {children && <SectionContent>{children}</SectionContent>}
    </Wrapper>
  )
}

const Wrapper = styled.section`
  position: relative;
  padding: ${({ theme }) => theme.components.InfoSection.paddingMobile};
  scroll-margin-top: ${({ theme }) => theme.components.common.scrollMarginTop};

  ${({ theme }) => theme.media.isDesktop} {
    padding: ${({ theme }) => theme.components.InfoSection.padding};
  }
`

const IconWrap = styled.div`
  ${({ theme }) => theme.components.InfoSection.iconStyleDesktop}
  ${({ theme }) => theme.media.isMobile} {
    ${({ theme }) => theme.components.InfoSection.iconStyleMobile}
  }
  ${Image} {
    width: 100%;
    height: 100%;
  }
`

const Badge = styled.span`
  background-color: ${({ theme }) => theme.colors.blick.brand};
  color: ${({ theme }) => theme.colors.texts.inverted};
  padding: 0 4px;
  border-radius: 2px;

  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
`
