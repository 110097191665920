export type TArchiveTranslations = typeof archiveTranslations

export const archiveTranslations = {
  title: '',
  description: <></>,
  backButton: '',
  showMore: '',
  published: '',
  playNow: '',
}
