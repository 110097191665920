import styled from 'styled-components/macro'
import { NavHashLink } from 'src/components/links/NavHasLink'
import { libertateaColors } from 'src/theme/themes/libertatea/colors'
// import { TNavLink, useNavigation } from 'src/containers/Header/useNavigation'
import { ReactComponent as CloseIcon } from 'src/assets/images/close_icon.svg'
import { useContext } from 'react'
import { DrawerContext } from 'src/drawer'
import { DRAWER_INDEX } from 'src/drawer/constants'
import { ReactComponent as MenuIcon } from 'src/assets/images/libertatea/menu_icon.svg'
import { Spacer } from 'src/components/Spacer'
import { TNavLink, useNavigation } from 'src/containers/libertatea/Header/useNavigation'

export type Props = {
  hideText?: boolean
}

export const MobileMenu = ({ hideText = false }: Props) => {
  const { openDrawer } = useContext(DrawerContext)
  const { navLinks, onNavigationClick } = useNavigation()

  const onMobileMenuClick = () => {
    openDrawer({
      id: DRAWER_INDEX.LIBERTATEA_MOBILE_MENU,
      content: <MobileNavigationMenuContent navLinks={navLinks} onNavigationClick={onNavigationClick} />,
      header: <MobileNavigationMenuHeader />,
      settings: {
        position: 'right',
        width: window.innerWidth - 88,
        dontDisableScroll: true,
      },
    })
  }

  return (
    <MobileMenuWrapper onClick={onMobileMenuClick}>
      <MenuIcon />
      {!hideText && (
        <>
          <Spacer $h={true} size={8} /> Meniu
        </>
      )}
    </MobileMenuWrapper>
  )
}

const MobileMenuWrapper = styled.div`
  font-weight: 300;
  font-size: 18px;
  line-height: 140%;
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const MobileNavigationMenuHeader = () => {
  const { closeDrawer } = useContext(DrawerContext)

  const onClose = () => {
    closeDrawer(DRAWER_INDEX.LIBERTATEA_MOBILE_MENU)
  }

  return (
    <HeaderWrapper>
      <StyledCloseIcon onClick={onClose} />
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.div`
  margin: 0px 18px;
  border-bottom: 1px solid ${libertateaColors.grey};
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const StyledCloseIcon = styled(CloseIcon)`
  width: 18px;
  margin-right: 6px;
  cursor: pointer;
  padding-left: 50px;

  path {
    fill: ${libertateaColors.greyDark};
  }
`

type ContentProps = {
  navLinks: TNavLink[]
  onNavigationClick: (title: string, hash: string) => void
}

export const MobileNavigationMenuContent = ({ navLinks, onNavigationClick }: ContentProps) => {
  const { closeDrawer } = useContext(DrawerContext)

  return (
    <ContentWrapper>
      {navLinks.map(
        ({ hash, title, to }) =>
          hash !== '#infoblock' && (
            <NavLink
              //@ts-ignore
              smooth="true"
              key={`${hash}_link`}
              to={{ pathname: to, hash: hash }}
              selected={window.location.hash === hash}
              onClick={() => {
                onNavigationClick(title, hash)
                closeDrawer(DRAWER_INDEX.LIBERTATEA_MOBILE_MENU)
              }}
            >
              {title}
            </NavLink>
          ),
      )}
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 18px;
`

const NavLink = styled(NavHashLink)<{ selected?: boolean }>`
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  color: ${({ selected }) => (selected ? libertateaColors.red : libertateaColors.greyDark)};
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 24px;
`
