import { Reducer } from 'redux'
import { getReduxFailureStates, getReduxFetchStates, getReduxSuccessStates } from 'src/utils/redux'
import { ActionTypes } from './enums'
import { Actions, TUserState } from './types'

export const initialState: TUserState = {
  data: {
    stats: null,
  },
  loading: {
    fetchUserStats: false,
  },
  error: {
    fetchUserStats: null,
  },
  resolved: {
    fetchUserStats: false,
  },
}

export const reducer: Reducer<TUserState, Actions> = (state = initialState, action): TUserState => {
  switch (action.type) {
    case ActionTypes.FETCH_USER_STATS: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'fetchUserStats'),
      }
    }
    case ActionTypes.FETCH_USER_STATS_SUCCESS: {
      return {
        ...state,
        data: {
          stats: action.payload,
        },
        ...getReduxSuccessStates(state, 'fetchUserStats'),
      }
    }
    case ActionTypes.FETCH_USER_STATS_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchUserStats', action.payload),
      }
    }
    default:
      return state
  }
}
