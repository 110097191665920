import { useMemo } from 'react'
import { getTheme } from './utils'

export const useTheme = () => {
  // const productId = useSelector(getProductId)

  const theme = useMemo(() => {
    return getTheme()
  }, [])

  return {
    theme,
    themeName: theme.name,
  }
}
