import { TArchiveTranslations } from 'src/translations/de/default/archive'

export const archiveTranslations: TArchiveTranslations = {
  title: 'Arhivă rebusuri',
  description: <>Distrează-te și pune-ți la încercare cunoștințele, completând rebusurile anterioare.</>,
  backButton: 'Înapoi',
  showMore: 'Arată mai multe rebusuri',
  published: 'Publicat pe',
  playNow: 'Completează Rebus',
}
