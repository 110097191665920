import React, { useImperativeHandle, useRef } from 'react'
import PropTypes from 'prop-types'

// import produce from 'immer';
import styled from 'styled-components/macro'

import { Direction, EnhancedProps } from './types'

import {
  CrosswordProvider,
  CrosswordProviderImperative,
  CrosswordProviderProps,
  crosswordProviderPropTypes,
} from './CrosswordProvider'
import { CrosswordGrid } from './CrosswordGrid'
import { DirectionClues } from './DirectionClues'
import { SelectedClue } from './SelectedClue'
import { useMedia } from 'src/utils/media/useMedia'

const crosswordPropTypes = {
  ...crosswordProviderPropTypes,

  /** the label for the "across" clues */
  acrossLabel: PropTypes.string,

  /** the label for the "down" clues */
  downLabel: PropTypes.string,
}

// @ts-expect-error TS doesn't allow non-optional props to be deleted, but we're
// building this into a new type!
delete crosswordPropTypes.children

// This somewhat non-obvious construction is to get the typings from
// CrosswordProvider where they are "better" than the default inferred types.
export type CrosswordProps = EnhancedProps<typeof crosswordPropTypes, Omit<CrosswordProviderProps, 'children'>>
export type CrosswordImperative = CrosswordProviderImperative

/**
 * The default export from the react-crossword library, `Crossword` renders an
 * answer grid and clues in a basic layout and provides access to most
 * functionality.
 */
export const Crossword = React.forwardRef<CrosswordImperative, CrosswordProps>(
  ({ acrossLabel, downLabel, ...props }, ref) => {
    const providerRef = useRef<CrosswordProviderImperative>(null)
    const { isDesktop } = useMedia()
    // expose some imperative methods
    useImperativeHandle(
      ref,
      () => ({
        /**
         * Sets focus to the crossword component.
         */
        focus: () => providerRef.current?.focus(),

        /**
         * Resets the entire crossword; clearing all answers in the grid and
         * also any persisted data.
         */
        reset: () => providerRef.current?.reset(),

        /**
         * Resets the focused word
         */
        resetWord: () => providerRef.current?.resetWord(),

        /**
         * Fills all the answers in the grid and calls the `onLoadedCorrect`
         * callback with _**every**_ answer.
         */
        fillAllAnswers: () => providerRef.current?.fillAllAnswers(),

        /**
         * Returns whether the crossword is entirely correct or not.
         *
         * @since 2.2.0
         */
        isCrosswordCorrect: () => !!providerRef.current?.isCrosswordCorrect(),

        /**
         * Sets the “guess” character for a specific grid position.
         *
         * @since 4.1.0
         */
        setGuess: (row: number, col: number, guess: string) => providerRef.current?.setGuess(row, col, guess),

        /**
         * Gets all the guesses.
         *
         * @since custom
         */
        getGuesses: () => providerRef.current?.getGuesses(),
        /**
         * Gets the guess for the current clue.
         *
         * @since custom
         */
        getGuess: () => providerRef.current?.getGuess(),
        /**
         * Sets the current state of the crossword.
         *
         * @since custom
         */
        setIpuzState: (state: (string | null)[][]) => providerRef.current?.setIpuzState(state),
        /**
         * Gets the current state of the crossword.
         *
         * @since custom
         */
        getIpuzState: () => providerRef.current?.getIpuzState(),
        /**
         * Unfocus word
         *
         * @since custom
         */
        unfocus: () => providerRef.current?.unfocus(),
        /**
         * Clear incorrect state of a word
         *
         * @since custom
         */
        clearIncorrect: (direction: Direction, number: string) =>
          providerRef.current?.clearIncorrect(direction, number),
        /**
         * Get current clue
         *
         * @since custom
         */
        getClue: () => providerRef.current?.getClue(),
        /**
         * Get is crossword completely filled no matter right or wrong
         *
         * @since custom
         */
        getIsCrosswordComplete: () => providerRef.current?.getIsCrosswordComplete(),
      }),
      [],
    )

    return (
      <CrosswordProvider {...props} ref={providerRef}>
        <Wrapper>
          <div>
            <SelectedClue />
            <CrosswordGrid />
          </div>
          {isDesktop && (
            <>
              <div></div>
              <DirectionClues direction="across" label={acrossLabel} />
              <div></div>
              <DirectionClues direction="down" label={downLabel} />
            </>
          )}
        </Wrapper>
      </CrosswordProvider>
    )
  },
)

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 26px 1fr 16px 1fr;

  ${({ theme }) => theme.media.isMobile} {
    display: block;
  }
`

Crossword.displayName = 'Crossword'
Crossword.propTypes = crosswordPropTypes
Crossword.defaultProps = {
  ...CrosswordProvider.defaultProps,
  acrossLabel: undefined,
  downLabel: undefined,
}
