import styled, { css } from 'styled-components/macro'

type Props = {
  size: number
  color?: string
  fullScreen?: boolean
  className?: string
}

export const Loading = (props: Props) => {
  return (
    <LoadingIndicator {...props}>
      <div />
      <div />
      <div />
    </LoadingIndicator>
  )
}

const LoadingIndicator = styled.div<Props>`
  display: inline-block;
  position: relative;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}px`};
    border: 2px solid red;
    border-radius: 50%;
    animation: loaderAnimation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ theme, color }) => color || theme.colors.blick.brand100} transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes loaderAnimation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  ${({ fullScreen }) =>
    fullScreen &&
    css`
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.2);
      z-index: ${({ theme }) => theme.zIndex.header - 1};
    `}
`
