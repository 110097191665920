import { useContext } from 'react'
import { DRAWER_INDEX, DrawerContext } from 'src/drawer'
import { UserDashBoardHeader as UserDashBoardHeaderLibertatea } from 'src/containers/libertatea/UserDashBoard/UserDashBoardHeader'
import { UserDashBoardContentAlt } from 'src/containers/UserDashBoard/UserDashBoardContentAlt'
import { useDispatch, useSelector } from 'react-redux'
import { getIsLoggedIn, getUserNickName } from 'src/api/auth/selectors'
import { login } from 'src/api/auth/actions'
import { getIsHeaderActive } from 'src/state/app/selectors'

export const useHeader = () => {
  const dispatch = useDispatch()
  // const isMiniHeaderActive = useSelector(getIsMiniHeaderActive)
  const isHeaderActive = useSelector(getIsHeaderActive)
  const userNickName = useSelector(getUserNickName)
  const isLoggedIn = useSelector(getIsLoggedIn)
  // const userSub = useSelector(getUserSub)
  const isMiniHeaderActive = false

  const { openDrawer } = useContext(DrawerContext)

  const onLoginClick = () => {
    dispatch(login('method', { returnUrl: window.location.href }))
  }

  const onClickUserIcon = () => {
    onLoginClick()
  }

  const onClickUserInfo = () => {
    openDrawer({
      id: DRAWER_INDEX.USER_DASHBOARD,
      header: <UserDashBoardHeaderLibertatea />,
      content: <UserDashBoardContentAlt />,
    })
  }

  return {
    userNickName,
    isLoggedIn,
    openDrawer,
    onClickUserIcon,
    onClickUserInfo,
    isMiniHeaderActive,
    isHeaderActive,
  }
}
