// import { useEffect, useState } from 'react'
// import { Route, Routes, useLocation } from 'react-router-dom'
// import { useSelector, useDispatch } from 'react-redux'

// import { config } from 'src/config'
// import { Path } from 'src/router/enums'
// import { getCurrentLocation } from 'src/state/router/selectors'
// import { getIsAuthResolved } from 'src/api/auth/selectors'
// import { locationChange } from 'src/state/router/actions'

// import { Footer } from 'src/components/Footer'
// import { DevToolBar } from 'src/containers/DevToolBar'
// import { Header } from 'src/containers/Header'
// import { QuizWidget } from 'src/containers/Quiz/widget/QuizWidget'
// import { EndOfRoundGroupSummary } from 'src/containers/Summary/EndOfRoundGroupSummary'
// import { EndOfRoundSummary } from 'src/containers/Summary/EndOfRoundSummary'
// import { EndOfSeasonSummary } from 'src/containers/Summary/EndOfSeasonSummary'
// import { JoinLinkModal } from 'src/containers/UserDashBoard/JoinLinkModal'
// import { WidgetLoginHooks } from 'src/containers/WidgetLoginHooks/WidgetLoginHooks'
// import { BetRoute } from 'src/routes/bet'
// import { Home } from 'src/routes/Home'
// import { LeaderBoard } from 'src/routes/LeaderBoard'
// import { LoginRedirect } from 'src/routes/LoginRedirect'
// import { Product } from 'src/routes/Product'
// import { EndOfSeasonGroupSummary } from 'src/containers/Summary/EndOfSeasonGroupSummary'

// import { getProductSeasonId } from 'src/api/product/selectors'
// import { AppEntryPoint } from './AppEntryPoint'
// import 'src/assets/fonts/fonts.css'
// import { WIDGET_PATHS } from 'src/router/constants'
// import { isFromPaths } from 'src/utils/router/isFromPaths'
// import { ScoreBetWidget } from 'src/containers/ScoreBet/widget/ScoreBetWidget'
// import { WidgetTest } from 'src/containers/WidgetTest'
// import { loadAutoHeightScript } from 'src/utils/loadScript'
// import { MatchBetWidget } from 'src/containers/MatchBets/widget/MatchBetWidget'
// import { waitForConsentChange } from 'src/tracking/gtm/actions'

export const BlickLayout = () => {
  // const dispatch = useDispatch()
  // const location = useLocation()

  // const isAuthResolved = useSelector(getIsAuthResolved)
  // const currentLocation = useSelector(getCurrentLocation)
  // const seasonId = useSelector(getProductSeasonId)

  // const [isWidgetPath, setIsWidgetPath] = useState<boolean>()

  // useEffect(() => {
  //   waitForConsentChange()
  // }, [])

  // useEffect(() => {
  //   if (isWidgetPath) {
  //     loadAutoHeightScript()
  //   }
  // }, [isWidgetPath])

  // useEffect(() => {
  //   const widgetPath = isFromPaths(WIDGET_PATHS, location.pathname)
  //   setIsWidgetPath(widgetPath)
  // }, [location])

  // //Tracking change of location
  // useEffect(() => {
  //   if (isAuthResolved) {
  //     if (!currentLocation || location.pathname !== currentLocation.pathname) {
  //       dispatch(locationChange(location, false))
  //     } else if (location.hash !== currentLocation.hash) {
  //       dispatch(locationChange(location, true))
  //     }
  //   }
  // }, [location, isAuthResolved, currentLocation, dispatch])

  return (
    <>
      Blick
      {/* {!isWidgetPath && <Header />}
      <AppEntryPoint>
        {!isWidgetPath && config.isDev && <DevToolBar />}
        <Routes>
          <Route path={Path.Home} element={<Home />} />
          <Route path={Path.Product} element={<Product />} />
          <Route path={Path.ProductLeaderBoard} element={<LeaderBoard />} />
          <Route path={Path.ProductLeaderBoardGroup} element={<LeaderBoard isGroup />} />
          <Route path={Path.LoginRedirect} element={<LoginRedirect />} />
          <Route path={`${Path.ProductBet}/*`} element={<BetRoute />} />
          <Route path={Path.EndOfSeasonSummary} element={<EndOfSeasonSummary />} />
          <Route path={Path.EndOfRoundSummary} element={<EndOfRoundSummary />} />
          <Route path={Path.EndOfRoundGroupSummary} element={<EndOfRoundGroupSummary />} />
          <Route path={Path.EndOfSeasonGroupSummary} element={<EndOfSeasonGroupSummary seasonId={seasonId} />} />

          <Route path={`${Path.QuizWidget}`} element={<QuizWidget />} />
          <Route path={`${Path.ScorebetWidget}`} element={<ScoreBetWidget />} />
          <Route path={`${Path.MatchbetWidget}`} element={<MatchBetWidget />} /> */}
      {/* TEST ONLY  */}
      {/* <Route path={`${Path.QuizWidgetTest}`} element={<WidgetTest widget="quizwidget" />} />
          <Route path={`${Path.ScorebetWidgetTest}`} element={<WidgetTest widget="scorebetwidget" />} />
          <Route path={`${Path.MatchbetWidgetTest}`} element={<WidgetTest widget="matchbetwidget" />} /> */}
      {/* TEST ONLY  */}
      {/* </Routes>

        {!isWidgetPath && <Footer />} */}
      {/* // loads protected callbacks that were called from a widget */}
      {/* {isWidgetPath === false && <WidgetLoginHooks />}
        <JoinLinkModal />
      </AppEntryPoint> */}
    </>
  )
}
